<template>
  <div>
    <!-- свернутый вид -->
    <importer-collapsed
      v-show="collapsed"
      v-model="search"
      :importers="importers"
      :loading="loading"
      :subject="subject"
      :branchdis="branch_disabled"
      :archive="show"
      @search="fetchImporters"
      @changeFields="branch_disabled = !branch_disabled"
      @openAchive="show = !show"
      @close="collapsed = false"
    />
    <!-- развернутый вид -->
    <v-row
      v-show="!collapsed"
      class="statistics-box mx-0"
    >
      <v-col cols="12">
        <v-row>
          <v-col cols="5">
            <v-row>
              <v-col
                v-if="selected"
                cols="12"
                class="d-flex align-center pb-0"
              >
                <span
                  class="font-weight-bold mt-1"
                > 2 Резидент </span>
                <v-menu offset-x>
                  <template #activator="{ on }">
                    <v-icon
                      tabindex="-1"
                      dense
                      class="mx-1 mt-2"
                      v-on="on"
                    >
                      mdi-tune
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      tag="button"
                      @click="show = true"
                    >
                      Cохранить организацию в справочник
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="7">
            <v-row>
              <v-col class="d-flex align-center justify-end pl-9">
                <v-autocomplete
                  v-model="search"
                  class="ml-16"
                  :loading="loading"
                  :readonly="loading"
                  :items="importers"
                  item-text="own_name"
                  :filter="filterBySearchField"
                  placeholder="Поиск в справочнике"
                  auto-select-first
                  return-object
                  append-icon="mdi-magnify"
                  background-color="white"
                  dense
                  outlined
                  hide-details="true"
                  @update:search-input="fetchImporters"
                />
                <v-btn
                  tabindex="-1"
                  min-width="32px"
                  text
                  class="ml-2"
                  @click="collapsed = true"
                >
                  <v-icon> mdi-chevron-up </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <div
        :id="block_id"
        v-click-outside="triggerOnFocus"
      >
        <!-- ИМПОРТЕР -->
        <v-col cols="12">
          <v-row class="pt-0">
            <v-col
              cols="3"
              align-self="end"
              class="pb-0"
            >
              <label>
                УНП
                <v-text-field
                  key="unp-with-mask"
                  ref="subject_unp"
                  v-model="subject.subject_unp"
                  v-mask="'#########'"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="6"
              class="pb-0"
              align-self="end"
            >
              <div class="d-flex align-center">
                <label class="mb-0">Наименование организации</label>
                <v-switch
                  v-model="isIndividual"
                  tabindex="-1"
                  label="ИП"
                  class="order-0 mx-2 pb-0"
                  color="#5CB7B1"
                  dense
                  hide-details="auto"
                />
              </div>
              <v-text-field
                ref="subject_name"
                v-model="subject.name"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="3"
              align-self="end"
              class="pb-0"
            >
              <label>
                Страна
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      tabindex="-1"
                      class="mx-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >mdi-tune</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="!customFieldChecker(countries, subject_json.country_letter, 'letterCode')"
                      @click="subject_json.country_letter = ''"
                    >
                      Значение из справочника
                    </v-list-item>
                    <v-list-item
                      v-else
                      @click="subject_json.country_letter = null"
                    >
                      Произвольное значение
                    </v-list-item>
                  </v-list>
                </v-menu>
              </label>
              <v-text-field
                v-show="!customFieldChecker(countries, subject_json.country_letter, 'letterCode')"
                ref="country_letter__custom"
                v-model="subject_json.country_letter"
                v-mask="'XX'"
                class="country_letter__custom"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                @input="(v) => subject_json.country_letter = v === '' ? null : v"
              />
              <v-autocomplete
                v-show="customFieldChecker(countries, subject_json.country_letter, 'letterCode')"
                ref="country_letter"
                v-model="subject_json.country_letter"
                class="country_letter__usual"
                auto-select-first
                :items="countries"
                item-text="text"
                item-value="letterCode"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :filter="filterBySearchField"
                @input="(v) => subject_json.country_letter = v === null ? '' : v"
              />
            </v-col>

            <v-col
              cols="2"
              class="pb-0"
            >
              <label>
                Индекс
                <v-text-field
                  ref="postal_index"
                  v-model="subject_json.postal_index"
                  v-mask="'######'"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="5"
              class="pb-0"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="region"
                  v-model="subject_json.region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="5"
              class="pb-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="city"
                  v-model="subject_json.city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>

            <v-col cols="9">
              <label>
                Адрес
                <v-text-field
                  ref="street_house"
                  v-model="subject_json.street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="3"
              lg="3"
            >
              <label>
                Телефон
                <v-text-field
                  v-show="subject_json.country_letter === 'BY'"
                  key="phone-with-mask"
                  ref="phone__custom"
                  v-model="subject_json.phone"
                  v-mask="'+375#########'"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
                <v-text-field
                  v-show="subject_json.country_letter !== 'BY'"
                  key="phone-without-mask"
                  ref="phone"
                  v-model="subject_json.phone"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <!-- <v-col cols="3">
              <label>
                <span class="font-weight-bold">Код там. органа
                  <v-menu
                    offset-x
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        tabindex="-1"
                        class="mx-1"
                        small
                        dense
                        v-bind="attrs"
                        v-on="on"
                      >mdi-tune</v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="subject_json.customs_code = ''"
                        v-if="!customFieldCheckerNull(customs, subject_json.customs_code, 'code')"
                      >
                        Произвольное значение
                      </v-list-item>
                      <v-list-item
                        @click="subject_json.customs_code = null"
                        v-else
                      >
                        Значение из справочника
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </span>
              </label>
              <v-text-field
                v-show="customFieldCheckerNull(customs, subject_json.customs_code, 'code')"
                v-model="subject_json.customs_code"
                ref="customs_code__custom"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                v-mask="'##'"
              />
              <v-autocomplete
                v-model="subject_json.customs_code"
                ref="customs_code"
                auto-select-first
                :items="customs"
                item-text="search"
                item-value="code"
                outlined
                dense
                hide-details="auto"
                background-color="white"
                :filter="filterBySearchField"
                v-show="!customFieldCheckerNull(customs, subject_json.customs_code, 'code')"
              />
            </v-col> -->
          </v-row>
        </v-col>

        <!-- ФИЛИАЛ -->
        <v-col
          cols="12"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <span class="font-weight-bold">Филиал</span>
              <v-btn
                min-width="32px"
                text
                class="ml-2"
                @click="branch = !branch"
              >
                <v-icon> {{ branch ? 'mdi-chevron-up' : 'mdi-chevron-down' }} </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-show="branch">
            <v-col
              cols="3"
              class="pt-0 pb-0"
            >
              <label>
                УНП
                <v-text-field
                  key="branch-unp-with-mask"
                  ref="subject_branch_unp"
                  v-model="subject.subject_branch_unp"
                  v-mask="'#########'"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="9"
              class="pb-0 pt-0"
            >
              <label>
                Наименование
                <v-text-field
                  ref="subject_branch_name"
                  v-model="subject.subject_branch_name"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="4"
              class="pb-0"
            >
              <label>
                Страна
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >mdi-tune</v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="!customFieldChecker(countries, subject_json.branch_country_letter, 'letterCode')"
                      @click="subject_json.branch_country_letter = ''"
                    >
                      Значение из справочника
                    </v-list-item>
                    <v-list-item
                      v-else
                      @click="subject_json.branch_country_letter = null"
                    >
                      Произвольное значение
                    </v-list-item>
                  </v-list>
                </v-menu>
              </label>
              <v-text-field
                v-show="!customFieldChecker(countries, subject_json.branch_country_letter, 'letterCode')"
                ref="branch_country_letter__custom"
                v-model="subject_json.branch_country_letter"
                v-mask="'XX'"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
              <v-autocomplete
                v-show="customFieldChecker(countries, subject_json.branch_country_letter, 'letterCode')"
                ref="branch_country_letter"
                v-model="subject_json.branch_country_letter"
                auto-select-first
                :items="countries"
                item-text="text"
                item-value="letterCode"
                :filter="filterBySearchField"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col
              cols="3"
              class="pb-0"
            >
              <label>
                Индекс
                <v-text-field
                  ref="branch_postal_index"
                  v-model="subject_json.branch_postal_index"
                  v-mask="'######'"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>

            <v-col
              cols="5"
              class="pb-0"
            >
              <label>
                Область/Район
                <v-text-field
                  ref="branch_region"
                  v-model="subject_json.branch_region"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col
              cols="5"
              class="pb-0"
            >
              <label>
                Город/Деревня
                <v-text-field
                  ref="branch_city"
                  v-model="subject_json.branch_city"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
            <v-col cols="7">
              <label>
                Адрес
                <v-text-field
                  ref="branch_street_house"
                  v-model="subject_json.branch_street_house"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="white"
                />
              </label>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </v-row>
    <archive
      :show.sync="show"
      @save="saveToArchive"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import {
  getCountryCodeByName,
  getCountryNameByCode,
  getSubjectFromResident,
} from "@/helpers/catalogs";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import customFieldChecker from '@/mixins/custom-field-checker'
import Archive from "./archive.vue";
import ImporterCollapsed from "./importer-collapsed.vue";
import { importerSelected } from "@/events/statistics"
import {subject as onSubject} from '@/events/statistics/control'
import {highlightField} from '@/helpers/control'
import SubjectModel from '@/models/statistics/subject/index'
import SubjectDictionary from '@/models/statistics/subject/dictionary'
import {validate, errorsToArray, errorsToPathList} from '@/helpers/validation'

export default {
  components: { Archive, ImporterCollapsed },
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, customFieldChecker],
  data: () => ({
    branch_disabled: true,
    branch: false,
    collapsed: false,
    block_id: "statistics-importer-block",
    isIndividual: false,
    saveToCatalog: false,
    items: [],
    search: null,
    loading: false,
    importers: [],
    show: false,
    subject: {
      declaration_id: "",
      subject_unp: "",
      name: "",
      subject_branch_unp: "",
      subject_branch_name: "",
    },
    subject_json: {
      country_letter: "",
      postal_index: "",
      region: "",
      city: "",
      street_house: "",
      phone: "",
      // customs_code: "",
      branch_country_letter: "",
      branch_postal_index: "",
      branch_region: "",
      branch_city: "",
      branch_street_house: "",
    },
    title: 'Импортер'
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      countries: "catalogs/getNsiCountries",
      customs: "catalogs/getNsiCustoms",
    }),
  },
  watch: {
    "selected.subject":{
      handler(){
        this.setFields();
        this.procedure_name = this.selected.type_declaration.customs_procedure
        if(this.selected.type_declaration.customs_procedure === 'ЭК'){
          this.title = 'Экспортер'
        }else{
          this.title = 'Импортер'
        }
      },
      deep:true
    },
    search(nv) {
      if (nv && Object.keys(nv).length) {
        this.updateFieldsOnSearch(nv);
        this.search = null;
        this.importers = [];
        this.uploadData().then(() => {
          this.$snackbar({text:"Обновлено [Резидент]",color:"green", top:false, right: false});
        }).catch((e) => {
          console.log(e)
          this.$snackbar({text:"Ошибка",color:"red", top:false, right: false});
        })
      }
    },
    "subject_json.country_letter": {
      handler() {
        this.$nextTick(() => {
          this.updateEventListeners();
        });
      },
    },
  },
  created(){
    onSubject.subscribe(({path}) => this.highlightField({path}))
  },
  beforeDestroy(){
    onSubject.unsubscribe()
  },
  methods: {
    highlightField,
    updateFieldsOnSearch(item) {
      const { subject, subject_json } = getSubjectFromResident({
        item,
        declaration_id: this.selected.subject.declaration_id,
      });

      this.subject = subject;
      this.subject_json = subject_json;
      // Обновляем контракт в блоке декларанта
      const {subject_doc_date, subject_doc_num} = item
      importerSelected.trigger({subject_doc_date, subject_doc_num})
    },
    setFields() {
      const {subject} = this.selected
      const {subject_json} = subject
      Object.keys(this.subject).forEach(key => {
        if(key in subject){
          this.subject[key] = subject[key]
        }
      })
      Object.keys(this.subject_json).forEach(key => {
        if(key in subject_json){
          this.subject_json[key] = subject_json[key]
        }
      })

      this.subject.name = subject.subject_name || subject.subject_person_name
      //Страна - справочник
      this.subject_json.country_letter =
        subject_json.country_letter || getCountryCodeByName(this.countries, subject_json.country_name);
      //Страна филиала - справочник
      this.subject_json.branch_country_letter =
        this.subject_json.branch_country_letter ||
        getCountryCodeByName(this.countries, this.subject_json.branch_country_name);

      this.isIndividual = subject.subject_name === null && subject.subject_person_name !== null;
      this.setHasChangesFalse();
    },
    uploadData() {
      const payload = {
        ...this.subject,
        subject_name: this.isIndividual ? null : this.subject.name,
        subject_person_name: this.isIndividual ? this.subject.name : null,
        subject_json: {
          ...this.subject_json,
          branch_country_name:
            getCountryNameByCode(
              this.countries,
              this.subject_json.branch_country_letter
            ) || null,
          country_name:
            getCountryNameByCode(
              this.countries,
              this.subject_json.country_letter
            ) || null,
        },
      };
      delete payload.name;
      const subject = this.convertEmptyStringsToNull(payload);

      const errorsObject = validate({
        payload:subject,
        model:SubjectModel,
        dictionary: SubjectDictionary
      })
      if(Object.keys(errorsObject).length){
        const errors = errorsToArray(errorsObject)
        const list = errorsToPathList(errorsObject)
        list.forEach(path => {
          this.highlightField({path, scroll:false})
        })
        return Promise.reject({validation:errors, payload})
      }
      return this.$store.dispatch("statistics/uploadBlockData", {
        name: "subject",
        value: subject,
      });
    },
    saveImporterToArchive({alias, docNum, docDate}) {
      this.$store.dispatch("division/saveResident", { alias, docNum, docDate }).then(() => {
        this.$snackbar({
          text: "Справочник обновлен",
          color: "blue",
          top:false, right: false
        });
      }).catch(err => {
        if(err.response.status === 400){
          this.$snackbar({text: err.response.data || "Ошибка",color:"red", top:false, right: false});
        }else{
          this.$snackbar({text: "Ошибка",color:"red", top:false, right: false});
        }

      });
    },
    saveToArchive(payload) {
      this.saveImporterToArchive(payload);
    },
    fetchImporters(value) {
      if (value && value.length > 3) {
        this.loading = true;
        this.$store
          .dispatch("division/searchResident", value)
          .then((res) => {
            this.loading = false;
            const items = res.data.map((i) => {
              return {
                ...i,
                search: `${i.name}-${i.own_name}-${i.unp}-${i.city}-${i.street}`,
              };
            });
            this.importers = [...items];
          })
          .catch(() => (this.loading = false));
      }
    },
  },
};
</script>
<style scoped>
.spacer {
  min-height: 16px;
}
</style>
