<template>
  <v-overlay :value="show">
    <v-card
      class="mx-auto pa-5"
      max-width="500px"
      color="white"
      light
    >
      <v-card-title class="mb-4">
        Cохранить организацию в справочник
      </v-card-title>
      <v-card-subtitle class="pb-1">
        Укажите псевдоним, который будет использоваться при поиске.
      </v-card-subtitle>

      <v-text-field
        v-model="alias"
        placeholder="Введите название"
        class="px-4"
        dense
        outlined
        hide-details="true"
      />

      <v-card-subtitle
        v-if="showContract"
        class="pb-1"
      >
        Реквизиты договора с данной организацией (необязательно)
      </v-card-subtitle>

      <div
        v-if="showContract"
        class="d-flex"
      >
        <v-text-field
          v-model="docNum"
          placeholder="Номер договора с таможенным представителем"
          class="px-4"
          dense
          outlined
          hide-details="true"
        />
        <v-text-field
          v-model="docDate"
          type="date"
          class="px-4"
          dense
          outlined
          hide-details="true"
          @change="docDate = deleteNulls($event)"
        />
      </div>

      <v-card-actions class="pa-4 justify-between">
        <v-btn
          dark
          color="red"
          class="px-5"
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          dark
          color="green"
          class="px-5"
          @click="save"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>
<script>
import { deleteNulls } from "@/helpers/inputs";
import { mapGetters } from 'vuex';
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    showContract: { // Отображается для резидента
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    alias: "",
    docNum: "",
    docDate: "",
  }),
  computed:{
    ...mapGetters({
      selected: 'statistics/getSelected'
    })
  },
  watch:{
    show(nv){
      if(nv === false){
        this.unset()
      }else{
        if(this.showContract){ // Если резидент
          this.alias = this.selected.subject.subject_name || ""
          const {subject_doc_num, subject_doc_date} = this.selected?.filler?.filler_json || {}
          this.docNum = subject_doc_num || ""
          this.docDate = subject_doc_date || ""
        }else{
          this.alias = this.selected.counter_agent.counter_agent_name || ""
        }
      }
    }
  },
  methods: {
    deleteNulls,
    unset(){
      this.alias= ""
      this.docNum =""
      this.docDate = ""
    },
    close() {
      this.$emit("update:show", false);
    },
    save() {
      const payload = this.showContract
        ? { alias: this.alias, docNum: this.docNum, docDate: this.docDate }
        : { alias: this.alias };
      this.$emit("save", payload);
      this.close();
    },
  },
};
</script>