<template>
  <v-col
    cols="12"
    class="statistics-box rounded-t-0"
    :class="{'blocked-box' : disabledView}"
  >
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <span class="font-weight-bold"> Путь товара
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-2"
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item @click="copyBlockToAll">
                Применить ко всем товарам
              </v-list-item>
              <v-list-item @click="copyBlockToSeveral">
                Применить к выбранным товарам
              </v-list-item>
            </v-list>
          </v-menu>
        </span>
      </v-col>
      <v-col cols="2">
        <label>
          10 (10а) Страна отпр.
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item
                @click="copyDispatchCountryToAll"
              >
                Применить ко всем товарам
              </v-list-item>
              <v-list-item @click="copyDispatchCountry">
                Применить к выбранным товарам
              </v-list-item>
              <v-list-item @click="openCountries('dispatch_country_letter')">
                Поиск в справочнике
              </v-list-item>
              <v-list-item
                v-if="customFieldChecker(countries, item.dispatch_country_letter, 'letterCode')"
                @click="item.dispatch_country_letter = null"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="item.dispatch_country_letter = ''"
              >
                Значение из справочника
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <v-text-field
          v-show="!customFieldChecker(countries, item.dispatch_country_letter, 'letterCode')"
          ref="dispatch_country_letter__custom"
          v-model="item.dispatch_country_letter"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="(v) => item.dispatch_country_letter = v === '' ? null : v"
        />
        <v-autocomplete
          v-show="customFieldChecker(countries, item.dispatch_country_letter, 'letterCode')"
          ref="dispatch_country_letter"
          v-model="item.dispatch_country_letter"
          auto-select-first
          :items="countries"
          item-text="text"
          item-value="letterCode"
          :filter="filterBySearchField"
          outlined
          dense
          background-color="white"
          hide-details="auto"
          class="mr-1 dispatch_country_letter"
          @input="(v) => item.dispatch_country_letter = v === null ? '' : v"
          @change="unsetRegion('dispatch')"
        />
      </v-col>
      <v-col
        cols="1"
        class="px-0"
        align-self="end"
      >
        <label>
          Регион
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item @click="copyDispatchRegionToAll">
                Применить ко всем товарам
              </v-list-item>
              <v-list-item @click="copyDispatchRegion">
                Применить к выбранным товарам
              </v-list-item>
              <v-list-item @click="openRegions('dispatch_country_region')">
                Поиск в справочнике
              </v-list-item>
              <v-list-item
                v-if="!customFieldCheckerNull(regionsForDispatchCountry, item.dispatch_country_region, 'code')"
                @click="item.dispatch_country_region = ''"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="item.dispatch_country_region = null"
              >
                Значение из справочника
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <v-text-field
          v-show="customFieldCheckerNull(regionsForDispatchCountry, item.dispatch_country_region, 'code')"
          ref="dispatch_country_region__custom"
          v-model="item.dispatch_country_region"
          v-mask="'XXX'"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
        <v-autocomplete
          v-show="!customFieldCheckerNull(regionsForDispatchCountry, item.dispatch_country_region, 'code')"
          ref="dispatch_country_region"
          v-model="item.dispatch_country_region"
          auto-select-first
          :items="regionsForDispatchCountry"
          item-text="code"
          item-value="code"
          :filter="filterBySearchField"
          outlined
          dense
          background-color="white"
          hide-details="auto"
          class="mr-1 dispatch_country_region"
        />
      </v-col>
      <v-col
        cols="3"
        align-self="end"
      >
        <label>
          <v-text-field
            :value="dispatchRegionName"
            disabled
            outlined
            dense
            background-color="#E1E1E1"
            hide-details="auto"
          />
        </label>
      </v-col>
      <v-col cols="2">
        <label>
          17 (17а) Страна назн.
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item @click="copyDestinationCountryToAll">
                Применить ко всем товарам
              </v-list-item>
              <v-list-item @click="copyDestinationCountry">
                Применить к выбранным товарам
              </v-list-item>
              <v-list-item @click="openCountries('destination_country_letter')">
                Поиск в справочнике
              </v-list-item>
              <v-list-item
                v-if="customFieldChecker(countries, item.destination_country_letter, 'letterCode')"
                @click="item.destination_country_letter = null"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="item.destination_country_letter = ''"
              >
                Значение из справочника
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <v-text-field
          v-show="!customFieldChecker(countries, item.destination_country_letter, 'letterCode')"
          ref="destination_country_letter__custom"
          v-model="item.destination_country_letter"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="(v) => item.destination_country_letter = v === '' ? null : v"
        />
        <v-autocomplete
          v-show="customFieldChecker(countries, item.destination_country_letter, 'letterCode')"
          ref="destination_country_letter"
          v-model="item.destination_country_letter"
          auto-select-first
          :items="countries"
          item-text="text"
          item-value="letterCode"
          :filter="filterBySearchField"
          outlined
          dense
          background-color="white"
          hide-details="auto"
          class="mr-1 destination_country_letter"
          @input="(v) => item.destination_country_letter = v === null ? '' : v"
          @change="unsetRegion('destination')"
        />
      </v-col>
      <v-col
        cols="1"
        align-self="end"
        class="px-0"
      >
        <label>
          Регион
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item @click="copyDestinationRegionToAll">
                Применить ко всем товарам
              </v-list-item>
              <v-list-item @click="copyDestinationRegion">
                Применить к выбранным товарам
              </v-list-item>
              <v-list-item @click="openRegions('destination_country_region')">
                Поиск в справочнике
              </v-list-item>
              <v-list-item
                v-if="!customFieldCheckerNull(regionsForDestinationCountry, item.destination_country_region, 'code')"
                @click="item.destination_country_region = ''"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="item.destination_country_region = null"
              >
                Значение из справочника
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <v-text-field
          v-show="customFieldCheckerNull(regionsForDestinationCountry, item.destination_country_region, 'code')"
          ref="destination_country_region__custom"
          v-model="item.destination_country_region"
          v-mask="'XXX'"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
        <v-autocomplete
          v-show="!customFieldCheckerNull(regionsForDestinationCountry, item.destination_country_region, 'code')"
          ref="destination_country_region"
          v-model="item.destination_country_region"
          auto-select-first
          :items="regionsForDestinationCountry"
          item-text="code"
          item-value="code"
          :filter="filterBySearchField"
          outlined
          dense
          background-color="white"
          hide-details="auto"
          class="mr-1 destination_country_region"
        />
      </v-col>
      <v-col
        cols="3"
        align-self="end"
      >
        <label>
          <v-text-field
            :value="desinationRegionName"
            disabled
            outlined
            dense
            background-color="#E1E1E1"
            hide-details="auto"
          />
        </label>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";
import { getCountryCodeByName, getCountryNameByCode, getCountryDigitCodeByLetterCode, unsetRegion } from "@/helpers/catalogs";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { showCatalog, catalogValueSelected } from "@/events/statistics";
import customFieldChecker from '@/mixins/custom-field-checker'
import highlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";

export default {
  name: "Transportation",
  mixins: [filterBySearchFieldMixin, customFieldChecker, highlightFieldWareSector, copyFieldMixin],
  props: {
    index: {
      required: true,
      type: Number,
    },
    visited: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    apply_for_wares: "",
    item: {
      id: "",
      declaration_id: "",
      dispatch_country_letter: "",
      dispatch_country_region: "",
      destination_country_letter: "",
      destination_country_region: "",
    },
    dialogApplyGroup: false,
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      selected_wares: "statistics/getSelectedWares",
      countries: "catalogs/getNsiCountries",
      regions: "catalogs/getNsiRegions",
      disabledView:"statistics/getVisibility"
    }),
    dispatchRegionName() {
      const region =
        this.regions.find(
          (i) => i.code === this.item.dispatch_country_region
        ) || {};
      return region?.name ?? "";
    },
    desinationRegionName() {
      const region =
        this.regions.find(
          (i) => i.code === this.item.destination_country_region
        ) || {};
      return region?.name ?? "";
    },
    regionsForDispatchCountry(){
      if(this.item.dispatch_country_letter){
        const code = getCountryDigitCodeByLetterCode(this.countries,this.item.dispatch_country_letter)
        return this.regions.filter(i => i.countryDigit === code || i.countryDigit === null)
      }
      return this.regions
    },
    regionsForDestinationCountry(){
      if(this.item.destination_country_letter){
        const code = getCountryDigitCodeByLetterCode(this.countries,this.item.destination_country_letter)
        return this.regions.filter(i => i.countryDigit === code || i.countryDigit === null)
      }
      return this.regions
    },
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    countries: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    item: {
      handler() {
        if (this.visited) this.updateEditableGoodsItem();
      },
      deep: true,
    },
  },
  created(){
    catalogValueSelected.subscribe(this.updatesResolver, [
      'ware/destination_country_letter',
      'ware/destination_country_region',
      'ware/dispatch_country_letter',
      'ware/dispatch_country_region',
    ])
  },
  beforeDestroy(){
    catalogValueSelected.unsubscribe()
  },
  methods: {
    unsetRegion,
    copyDispatchCountry(){
      const {keys, values} = this.getPreparedFieldsPayload(['dispatch_country_letter', 'dispatch_country_name'])
      this.copy(keys, values, 'apply', this.index)
    },
    copyDispatchCountryToAll(){
      const {keys, values} = this.getPreparedFieldsPayload(['dispatch_country_letter', 'dispatch_country_name'])
      this.copyToAll(keys, values)
    },
    copyDispatchRegion(){
      this.copy('dispatch_country_region', this.item.dispatch_country_region, 'apply', this.index)
    },
    copyDispatchRegionToAll(){
      this.copyToAll('dispatch_country_region', this.item.dispatch_country_region)
    },
    copyDestinationCountry(){
      const {keys, values} = this.getPreparedFieldsPayload(['destination_country_letter', 'destination_country_name'])
      this.copy(keys, values, 'apply', this.index)
    },
    copyDestinationCountryToAll(){
      const {keys, values} = this.getPreparedFieldsPayload(['destination_country_letter', 'destination_country_name'])
      this.copyToAll(keys, values)
    },
    copyDestinationRegion(){
      this.copy('destination_country_region', this.item.destination_country_region, 'apply', this.index)
    },
    copyDestinationRegionToAll(){
      this.copyToAll('destination_country_region', this.item.destination_country_region)
    },
    copyBlockToAll(){
      const {keys, values} = this.getPreparedBlockPayload()
      this.copyToAll(keys, values)
    },
    copyBlockToSeveral(){
      const {keys, values} = this.getPreparedBlockPayload()
      this.copy(keys, values, 'apply', this.index)
    },
    getPreparedFieldsPayload(keysToPick = []){
      const payload = this.getFullBody()
      const prepared = payload.pick(keysToPick)
      const keys = Object.keys(prepared)
      const values = Object.values(prepared)
      return {keys, values}
    },
    getPreparedBlockPayload(){
      const payload = this.getFullBody()
      const keys = Object.keys(payload)
      const values = Object.values(payload)
      return {keys, values}
    },
    getFullBody(){
      return {
        destination_country_letter: this.item.destination_country_letter,
        destination_country_name:getCountryNameByCode(this.countries, this.item.destination_country_letter),
        destination_country_region: this.item.destination_country_region,
        dispatch_country_letter: this.item.dispatch_country_letter,
        dispatch_country_name: getCountryNameByCode(this.countries, this.item.dispatch_country_letter),
        dispatch_country_region: this.item.dispatch_country_region,
      }
    },
    updatesResolver({id, code, field}){
      if(id === this.item.id){
        if(code && field){
          this.$emit('update:visited', true)
          const key = field.split('/')[1]
          this.item[key] = code
        }
      }
    },
    setFields() {
      const item = this.selected_wares[this.index];
      const {
        id,
        declaration_id,
        dispatch_country_name,
        dispatch_country_letter,
        dispatch_country_region,
        destination_country_name,
        destination_country_letter,
        destination_country_region,
      } = item || {};

      this.item.id = id;
      this.item.declaration_id = declaration_id;

      this.item.dispatch_country_letter =
        dispatch_country_letter ||
        getCountryCodeByName(this.countries, dispatch_country_name);
      this.item.dispatch_country_region = dispatch_country_region;

      this.item.destination_country_letter =
        destination_country_letter ||
        getCountryCodeByName(this.countries, destination_country_name) ;
      this.item.destination_country_region = destination_country_region;


      //Подстановка значения которого нет в справочнике - возможно при загрузке xlsx
      if(dispatch_country_name && !this.item.dispatch_country_letter){
        this.item.origin_country_letter = dispatch_country_name
      }
      if(destination_country_name && !this.item.destination_country_letter){
        this.item.origin_country_letter = destination_country_name
      }
    },
    updateEditableGoodsItem() {
      const payload = {
        ...this.item,
        destination_country_name: getCountryNameByCode(
          this.countries,
          this.item.destination_country_letter
        ),
        dispatch_country_name: getCountryNameByCode(
          this.countries,
          this.item.dispatch_country_letter
        ),
      };
      const trans = convertEmptyStringsToNull(payload);
      this.$emit("update", trans);
    },
    openCountries(field) {
      showCatalog.trigger({
        id:this.item.id,
        field:`ware/${field}`,
        type: "countries",
        search: this.item[field],
      });
    },
    openRegions(field) {
      const param = field.indexOf('dispatch') !== -1 ? 'dispatch_country_letter': 'destination_country_letter'
      showCatalog.trigger({
        id:this.item.id,
        field:`ware/${field}`,
        type: "regions",
        search: this.item[field],
        filter:{
          countryDigit:getCountryDigitCodeByLetterCode(this.countries,this.item[param])
        }
      });
    },
  },
};
</script>
<style scoped>
label {
  margin: 0 !important;
}
</style>
