<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row class="my-auto mx-0 statistics-box">
          <v-col cols="6">
            <v-row>
              <v-col cols="12">
                <v-tooltip
                  v-for="(btn, idx) in visibleActions"
                  :key="idx"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      class="mr-2"
                      min-width="36px"
                      max-width="36px"
                      v-bind="attrs"
                      :disabled="btn.loading"
                      :loading="btn.loading"
                      v-on="on"
                      @click="callFunction(btn.action, { action: btn.action })"
                    >
                      <v-icon :color="btn.color || ''">
                        {{ btn.icon }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ btn.name }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                :id="block_id"
                v-click-outside="triggerOnFocus"
                cols="12"
              >
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      ref="out_number"
                      v-model="about_declaration.out_number"
                      :error="isOutNumberError"
                      maxlength="50"
                      placeholder="Исходящий номер"
                      outlined
                      dense
                      hide-details="auto"
                      :background-color="
                        blocked_visibility ? 'grey lighten-2' : 'white'
                      "
                      :disabled="blocked_visibility"
                    />
                  </v-col>
                  <p class="slash">
                    /
                  </p>
                  <v-col cols="4">
                    <v-text-field
                      ref="remark"
                      v-model="about_declaration.remark"
                      placeholder="Заметка"
                      outlined
                      dense
                      hide-details="auto"
                      background-color="white"
                      class="remark"
                    />
                  </v-col>
                  <v-col class="pb-0 pr-6">
                    <custom-date-picker
                      ref="declaration_date"
                      v-model="about_declaration.declaration_date"
                      :visited.sync="fields_been_visited"
                      :has-changes.sync="hasChanges"
                      :disabled="blocked_visibility"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <document-control
            v-if="!pinned.includes('controls')"
            source="statistics/getControl"
            pin-name="controls"
            class="ml-2"
          >
            <template #action>
              <common-errors-action />
            </template>
          </document-control>
        </v-row>
      </v-col>
    </v-row>
    <component
      :is="activeComponent"
      :ref="getReferenceAsync"
      :show.sync="showComponent"
      :params="activeComponentParams"
    />
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

import CommonErrorsAction from "@/components/statistics/controls/common-errors-action.vue";
import CustomDatePicker from "@/components/shared/custom-date-picker";
import DocumentControl from "@/components/shared/document-control.vue";
import blockVisibility from "@/mixins/block-visibility";
import {
  downloadXml,
  getFilenameFromContentDisposition,
  downloadFile,
  sendToNces, validateRequiredParams, performDocumentControl, getXmlString, getSignedDocument, displayControlResponse,
} from "@/helpers/documents";
import {about_declaration as onAboutDeclaration} from "@/events/statistics/control";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import ToolboxMixin from "@/mixins/toolbox.mixin";

export default {
  name: "StatisticsToolbox",
  components: {
    CustomDatePicker,
    DocumentControl,
    CommonErrorsAction,
  },
  mixins: [blockAutoUpdate, blockVisibility, ToolboxMixin],
  data: () => ({
    block_id: "statistics-about-declaration",
    namespace:"statistics",
    selectedPto:"555",
    date: null,
    actions: [
      {
        visible: true,
        loading: false,
        icon: "mdi-upload",
        action: "sendDocument",
        name: "Отправить в ИСТО",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-file-pdf-box",
        action: "downloadPdf",
        name: "Скачать PDF",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-file-xml-box",
        action: "downloadXml",
        name: "Скачать XML",
      },
      {
        visible: true,
        loading: false,
        icon: "mdi-content-copy",
        action: "copyDocument",
        name: "Копировать документ",
        component: () => import("@/components/documents/copy-document.vue"),
      },
      {
        visible: false,
        icon: "",
        action: "showXsdControlResponse",
        name: "Контроль XSD",
        component: () =>
          import("@/components/documents/xsd-control-response.vue"),
      },
      {
        visible: false,
        icon: "",
        action: "showDocumentControlResponse",
        name: "Контроль FLK",
        component: () =>
          import("@/components/shared/document-control-response.vue"),
      },
      {
        visible: true,
        icon: "mdi-bell",
        action: "setControlled",
        name: "Уведомление",
      },
      {
        visible: true,
        icon: "mdi-star",
        action: "setFavorite",
        name: "В избранное",
      },
    ],
    about_declaration: {
      out_number: "",
      remark: "",
      declaration_date: null,
      declaration_id: null,
    },
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      user: "auth/getUser",
      pinned: "ui/getPinned",
      requests: 'statistics/getRequests'
    }),
    isOutNumberError(){
      return this.about_declaration.out_number?.length > 50
    }
  },
  watch: {
    selected() {
      this.setFields();
    },
  },
  created() {
    onAboutDeclaration.subscribe(this.highlightField);
  },
  beforeDestroy() {
    onAboutDeclaration.unsubscribe();
  },
  methods: {
    validateRequiredParams,
    performDocumentControl,
    displayControlResponse,
    getXmlString,
    getSignedDocument,
    sendToNces,
    sendDocument(){
      this.setLoading('sendDocument', true)
      this.validateRequiredParams()
        .then(this.performDocumentControl)
        .then(this.displayControlResponse)
        .then(this.getXmlString)
        .then(this.getSignedDocument)
        .then(this.sendToNces)
        .catch(() => {})
        .finally(() => this.setLoading('sendDocument', false)
        )
    },
    setFields() {
      const {about_declaration} = this.selected || {};
      for (let key in about_declaration) {
        if (key in this.about_declaration) {
          this.about_declaration[key] = about_declaration[key];
        }
      }
      this.setHasChangesFalse();
    },
    uploadData() {
      const about = this.convertEmptyStringsToNull(this.about_declaration);
      return this.$store.dispatch("statistics/uploadBlockData", {
        name: "about_declaration",
        value: about,
      });
    },
    copyDocument({action}) {
      this.activeAction = this.getActionIndex(action);
      this.showComponent = true;
    },
    downloadPdf() {
      this.$store
        .dispatch("statistics/downloadPdf", this.selected.id)
        .then((res) => {
          downloadFile({
            data: res.data,
            type: "application/pdf",
            name: getFilenameFromContentDisposition(res),
          });
        })
        .catch(() => this.$error("Ошибка"));
    },
    downloadXml() {
      this.$store
        .dispatch("statistics/getXml", this.selected.id)
        .then((res) => {
          downloadXml(res.data, getFilenameFromContentDisposition(res));
        });
    },
    setControlled() {
      this.$store
        .dispatch("statistics/setControlled")
        .then(() => {
          const text = this.selected.controlled
            ? "Уведомление включено"
            : "Уведомление отключено";
          this.$info(text);
        })
        .catch((err) => {
          const message = typeof err === "string" ? err : "";
          this.$error(message)
        });
    },
    setFavorite() {
      this.$store
        .dispatch("statistics/setFavorite")
        .then(() => {
          const text = this.selected.favorite
            ? "Документ добавлен в избранные"
            : "Документ удален из избранных";
          this.$info(text)
        })
        .catch((err) => {
          const message = typeof err === "string" ? err : "";
          this.$error(message)
        });
    },
  },
};
</script>
<style scoped>
.burger span {
  display: block;
  background: #333333;
  height: 2px;
  width: 100%;
  margin: 10px 0;
}

.slash {
  display: block;
  text-align: center;
  height: 100%;
  margin: auto 0;
}
</style>
