<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <div
      class="statistics-box"
      :class="{'blocked-box' : blocked_visibility}"
    >
      <v-col
        cols="12"
        class="pb-0 d-flex justify-space-between"
      >
        <div class="mt-2">
          <span class="font-weight-bold">
            23 Сведения о лице, заполнившем декларацию
            <v-menu offset-x>
              <template #activator="{ on }">
                <v-icon
                  dense
                  tabindex="-1"
                  class="mx-1"
                  v-on="on"
                >mdi-tune</v-icon>
              </template>
              <v-list>
                <v-list-item @click="fillFromUser">
                  Заполнить из личного кабинета
                </v-list-item>
                <v-list-item
                  @click="showPersonalData"
                >Персональные данные</v-list-item>
                <v-list-item
                  v-if="allowChangePerformer"
                  @click="changePerformer"
                >Сменить владельца</v-list-item>
              </v-list>
            </v-menu>
          </span>
        </div>

        <v-btn
          min-width="32px"
          text
          tabindex="-1"
          @click="collapsed = !collapsed"
        >
          <v-icon>
            {{
              collapsed ? "mdi-chevron-down" : "mdi-chevron-up"
            }}
          </v-icon>
        </v-btn>
      </v-col>
      <v-row
        v-show="collapsed"
        class="px-3 mb-1"
      >
        <v-col>
          {{ fillerName }}
        </v-col>
        <v-col>{{ filler_json.auth_doc_name }}</v-col>
        <v-col>{{ filler_json.auth_doc_number }}</v-col>
        <v-col>{{ fillerDocDate }}</v-col>
        <v-col>
          {{ filler_json.subject_doc_num }}
        </v-col>
      </v-row>
      <v-row
        v-show="!collapsed"
        class="px-3"
      >
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Фамилия
            <v-text-field
              ref="last_name"
              v-model="filler_json.last_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Имя
            <v-text-field
              ref="first_name"
              v-model="filler_json.first_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Отчество
            <v-text-field
              ref="middle_name"
              v-model="filler_json.middle_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="4"
          class="pb-0"
        >
          <label>
            Должность
            <v-text-field
              ref="position"
              v-model="filler_json.position"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="pb-0"
        >
          <label>
            Телефон
            <v-text-field
              ref="phone"
              v-model="filler_json.phone"
              v-mask="'+375 ## #######'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>

        <v-col
          cols="12"
          class="pb-0"
        >
          <span
            class="font-weight-bold"
          >Документ, удостоверяющий полномочия
          </span>
        </v-col>

        <v-col
          cols="4"
          class="py-0"
        >
          <label>
            Наименование документа
            <v-text-field
              ref="auth_doc_name"
              v-model="filler_json.auth_doc_name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="2"
          class="py-0"
        >
          <label>
            Номер документа
            <v-text-field
              ref="auth_doc_number"
              v-model="filler_json.auth_doc_number"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>

        <!-- date 1 -->

        <v-col
          cols="2"
          class="py-0"
        >
          <label>
            Дата действия с
            <custom-date-picker
              ref="auth_doc_d_on"
              v-model="filler_json.auth_doc_d_on"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />
          </label>
        </v-col>

        <!-- date 2 -->
        <v-col
          cols="2"
          class="py-0"
        >
          <label>
            Дата действия по
            <custom-date-picker
              ref="auth_doc_d_off"
              v-model="filler_json.auth_doc_d_off"
              :visited.sync="fields_been_visited"
              :has-changes.sync="hasChanges"
            />

          </label>
        </v-col>

        <v-col
          cols="12"
          class="pb-0 d-flex align-center"
        >
          <span class="font-weight-bold"> Организация</span>

          <v-switch
            v-model="isIndividual"
            tabindex="-1"
            label="ИП"
            class="order-0 mx-2"
            color="#5CB7B1"
            dense
            hide-details="auto"
          />
        </v-col>
        <v-col
          cols="2"
          class="pt-0"
        >
          <label>
            УНП
            <v-text-field
              ref="declarant_unp"
              v-model="filler_json.declarant_unp"
              v-mask="'#########'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
        <v-col
          cols="3"
          class="pt-0"
        >
          <label>
            Наименование
            <v-text-field
              ref="declarant_name"
              v-model="filler_json.name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </label>
        </v-col>
      </v-row>
    </div>

    <div
      v-show="!collapsed"
      class="statistics-box mt-7 mb-5"
      :class="{'blocked-box' : blocked_visibility}"
    >
      <v-col
        cols="12"
        class="pb-0"
      >
        <span class="font-weight-bold"> Таможенный представитель </span>
      </v-col>
      <v-row class="px-3">
        <v-col
          cols="6"
          class="d-flex px-0"
        >
          <v-col
            cols="4"
            class="py-0"
          >
            <label>
              Реестр
              <v-text-field
                ref="customs_agent_license_num"
                v-model="filler_json.customs_agent_license_num"
                :disabled="dvsn && dvsn.divisionType === 'IMP'"
                :background-color="dvsn && dvsn.divisionType === 'IMP' ? 'grey lighten-2' : 'white'"
                outlined
                dense
                hide-details="auto"
              />
            </label>
          </v-col>
          <v-col
            cols="4"
            class="py-0"
          >
            <label>
              Договор
              <v-text-field
                ref="subject_doc_num"
                v-model="filler_json.subject_doc_num"
                :disabled="dvsn && dvsn.divisionType === 'IMP'"
                outlined
                dense
                hide-details="auto"
                :background-color="dvsn && dvsn.divisionType === 'IMP' ? 'grey lighten-2' : 'white'"
              />
            </label>
          </v-col>

          <v-col
            cols="4"
            class="py-0"
          >
            <label>
              Дата договора
              <custom-date-picker
                ref="subject_doc_date"
                v-model="filler_json.subject_doc_date"
                :disabled="dvsn && dvsn.divisionType === 'IMP' ? true : false"
                :visited.sync="fields_been_visited"
                :has-changes.sync="hasChanges"
              />
            </label>
          </v-col>
        </v-col>

        <v-col
          cols="6"
          class="mx-0"
        >
          <v-col
            cols="6"
            class="py-0 px-0 ml-auto mr-0"
          >
            <label>
              Аттестат таможенного представителя
              <v-text-field
                ref="certificate_number"
                v-model="filler_json.certificate_number"
                outlined
                :disabled="dvsn && dvsn.divisionType === 'IMP'"
                dense
                hide-details="auto"
                :background-color="dvsn && dvsn.divisionType === 'IMP' ? 'grey lighten-2' : 'white'"
              />
            </label>
          </v-col>
        </v-col>
      </v-row>
    </div>
    <component
      :is="activeComponent"
      :id="selectedId"
      module="statistics"
      :root-user-id="rootUserId"
      :show.sync="showComponent"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { deleteNulls } from "@/helpers/inputs";
import { getInitialsString } from "@/helpers/text";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import moment from "moment";
import { importerSelected } from "@/events/statistics";
import CustomDatePicker from "@/components/shared/custom-date-picker"
import blockVisibility from '@/mixins/block-visibility'
import {filler as onFiller} from '@/events/statistics/control'
import {highlightField} from '@/helpers/control'
export default {
  components:{
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, blockVisibility],
  data: () => ({
    collapsed: true,
    block_id: "statistics-declarant-block",
    isIndividual: false,
    rootUserId: null,
    filler: {
      declaration_id: null,
    },
    filler_json: {
      last_name: "",
      first_name: "",
      middle_name: "",
      position: "",
      phone: "",
      auth_doc_name: "",
      auth_doc_number: "",
      auth_doc_d_on: "",
      auth_doc_d_off: "",
      declarant_unp: "",
      customs_agent_license_num: "",
      subject_doc_num: "",
      subject_doc_date: "",
      certificate_number: "",
      name: "",
    },
    showComponent:false,
    activeComponent:null,
    actions:[
      {
        action:"changePerformer",
        component:() => import('@/components/documents/change-performer.vue')
      }
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      dvsn: 'auth/getSingleDivision',
      user: "auth/getUser"
    }),
    allowChangePerformer(){
      return this.selected.user_id === this.userId ? true : this.$can('doc.div.user.update')
    },
    selectedId(){
      return this.selected.id
    },
    userId(){
      return this.user?.user?.id
    },
    fillerName() {
      return getInitialsString(this.filler_json);
    },
    fillerDocDate() {
      const on = moment(this.filler_json.auth_doc_d_on).isValid()
        ? moment(this.filler_json.auth_doc_d_on).format("DD.MM.YYYY")
        : null;
      const off = moment(this.filler_json.auth_doc_d_off).isValid()
        ? moment(this.filler_json.auth_doc_d_off).format("DD.MM.YYYY")
        : null;
      const on_value = on && on !== null ? `с ${on}` : "";
      const off_value = off && off !== null ? `по ${off}` : "";
      return [on_value, off_value].join(" ").trim();
    },
  },
  watch: {
    selected() {
      this.setFields();
    },
    blocked_visibility(){
      if(this.blocked_visibility){
        this.collapsed = false
      }else{
        this.collapsed = true
      }
    }
  },
  created() {
    importerSelected.subscribe(this.updateContract);
    this.$store.dispatch('auth/loadSingleDivisions', this.user.user.activeDivisionId)
    onFiller.subscribe(this.prepareView)
  },
  beforeDestroy() {
    onFiller.unsubscribe()
    importerSelected.unsubscribe();
  },
  methods: {
    deleteNulls,
    highlightField,
    changePerformer(){
      const action = this.actions.find(i => i.action === 'changePerformer')
      this.activeComponent = action.component || null
      this.showComponent = true
    },
    prepareView(data){
      this.collapsed ? this.collapsed = false : false;
      this.$nextTick(() => {
        this.highlightField(data)
      })
    },
    updateContract({ subject_doc_num, subject_doc_date }) {
      this.filler_json.subject_doc_num = subject_doc_num;
      this.filler_json.subject_doc_date = subject_doc_date;
      this.fieldsBeenVisitedByUser();
      this.triggerOnChange();
    },
    setFields() {
      this.rootUserId = this.selected.user_id
      const {
        filler: { declaration_id, filler_json },
      } = this.selected || {};

      this.filler.declaration_id = declaration_id;
      for (let key in filler_json) {
        if (key in this.filler_json) {
          this.filler_json[key] = filler_json[key];
        }
      }

      // Наименование
      this.filler_json.name =
        filler_json.declarant_name || filler_json.declarant_person_name;
      this.isIndividual =
        filler_json.declarant_name === null &&
        filler_json.declarant_person_name !== null;
      this.setHasChangesFalse();
    },
    showPersonalData(){
      const route = this.$router.resolve({ path: `/user` });
      window.open(route.href, "_blank");
    },
    fillFromUser() {
      this.$store
        .dispatch("auth/getUserPersonalById", this.user.user.id)
        .then((res) => {
          const user = res.data;
          this.filler_json = {
            ...this.filler_json,
            last_name: user.last_name,
            first_name: user.first_name,
            middle_name: user.middle_name,
            position: user.uid_user_position,
            auth_doc_number: user.uid_doc_number,
            auth_doc_d_on: user.uid_doc_d_on,
            auth_doc_d_off: user.uid_doc_d_off,
            certificate_number: user.user_certificate,
            phone: user.uid_phone,
            auth_doc_name: user.uid_doc_name,
          };
          this.fieldsBeenVisitedByUser();
          this.triggerOnChange();
        })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка получения данных",
            color: "red",
            top: false,
          });
        });
    },
    uploadData() {
      const payload = {
        declaration_id: this.filler.declaration_id,
        filler_json: {
          ...this.filler_json,
          declarant_name: this.isIndividual ? null : this.filler_json.name,
          declarant_person_name: this.isIndividual
            ? this.filler_json.name
            : null,
        },
      };

      delete payload.filler_json.name;

      const filler = this.convertEmptyStringsToNull(payload);

      return this.$store.dispatch("statistics/uploadBlockData", {
        name: "filler",
        value: filler,
      });
    },
  },
};
</script>
