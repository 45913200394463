<template>
  <div>
    <v-row class="statistics-box mx-0">
      <v-col
        cols="5"
        class="d-flex justify-space-between pt-1"
      >
        <div class="d-flex align-center">
          <span class="font-weight-bold"> 2 Резидент
            <v-menu offset-x>
              <template #activator="{ on }">
                <v-icon
                  dense
                  class="mx-0"
                  v-on="on"
                >
                  mdi-tune
                </v-icon>
              </template>
              <v-list>
                <v-list-item
                  tag="button"
                  @click="openAchive"
                >
                  Cохранить организацию в справочник
                </v-list-item>
                <v-list-item
                  tag="button"
                  @click="changeFields"
                >
                  {{ branchdis ? 'Активировать поля филиала' : 'Отключить поля филиала' }}
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </div>
      </v-col>
      <v-col
        cols="7"
        class="d-flex justify-end align-center pl-9"
      >
        <v-autocomplete
          class="ml-16"
          :loading="loading"
          :readonly="loading"
          :items="importers"
          item-text="own_name"
          :filter="filterBySearchField"
          auto-select-first
          return-object
          append-icon="mdi-magnify"
          placeholder="Поиск в справочнике"
          background-color="white"
          dense
          outlined
          hide-details="true"
          @update:search-input="(val) => $emit('search', val)"
          @change="(val) => $emit('input', val)"
        />
        <v-btn
          class="ml-2"
          min-width="32px"
          text
          @click="() => $emit('close')"
        >
          <v-icon> mdi-chevron-down </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="7">
        {{ subject.subject_unp }} <span class="mx-5">{{ subject.name }}</span>
      </v-col>
      <v-col
        cols="5"
        class="pl-6"
      >
        {{ subject.subject_branch_unp }}
        <span class="mx-5">{{ subject.subject_branch_name }}</span>
      </v-col>
    </v-row>
    <!-- <Archive
      :show.sync="show"
      @save="saveToArchive"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
// import Archive from './archive.vue'
export default {
  // components: {Archive},
  mixins: [filterBySearchFieldMixin],
  props: {
    subject: {
      type: Object,
      required: true,
    },
    loading: {
      required: true,
      type: Boolean,
    },
    importers: {
      required: true,
      type: Array,
    },
    branchdis: {
      required: true,
      type: Boolean
    },
    archive: {
      required: true,
      type: Boolean
    }
  },
  data:() => ({
    title: 'Импортер',
    show:  false
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected"
    }),
  },
  watch: {
    selected:{
      handler(){
        if(this.selected.type_declaration.customs_procedure === 'ЭК'){
          this.title = 'Экспортер'
        }else{
          this.title = 'Импортер'
        }
      },
      deep:true
    },
  },
  methods: {
    changeFields(){
      this.$emit('changeFields')
    },
    openAchive(){
      this.$emit('openAchive')
    },
    fetchImporters(val) {
      this.$emit("input", val);
    }
  },
};
</script>
