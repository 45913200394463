import get from "lodash.get"

//Валидирует объект на основании заданных параметров модели
export function validate({payload, dictionary, model, path}){
  try{
    if(typeof payload === 'object' && payload !== null && !Array.isArray(payload)){
      const result = {}
      Object.keys(payload).forEach(key => {
        const validation = validate({
          model,
          dictionary,
          payload:payload[key],
          path: path ? [path, key].join('.'): key})
        Object.keys(validation).length > 0 ? result[key] = validation: false
      })
      return result
    }else{
      const rules = get(model, path) || {}
      const errors = {}
      Object.keys(rules).forEach(key => {
        if(key === 'maxLength' && payload && payload.length > rules[key] ){
          const dictionaryName = get(dictionary, path)
          errors[key] = `Длина поля "${dictionaryName}" превышает ${rules[key]} символов`
        }
      })
      return errors
    }
  }catch(e){
    console.log(e)
  }
}

// Возвращает массив в путями в формате root.parent.child
export function errorsToPathList(payload){
  const listWithParam = getPathRecursive({payload})
  return listWithParam.filter(i => typeof i === 'string').map(element => {
    const arry = element.split('.')
    arry.pop()
    return arry.join('.') // Убираем последний элемент - тк это параметр модели
  })
}


// Возвращает массив в путями в формате  field.`maxWidth`
export function getPathRecursive({payload, path}){
  try{
    if(typeof payload === 'object' && payload !== null && !Array.isArray(payload)){
      const list = []
      Object.keys(payload).forEach(key => {
        const err = getPathRecursive({payload:payload[key], path: path ? [path, key].join('.'): key})
        Array.isArray(err) ? list.push(...err) : list.push(err)
      })
      return list
    }else{
      return path
    }
  }catch(e){
    console.log(e)
  }
}

// Возвращает массив текстовых ошибок
export function errorsToArray(payload){
  if(typeof payload === 'object' && payload !== null && !Array.isArray(payload)){
    const errors = []
    Object.keys(payload).forEach(key => {
      const err = errorsToArray(payload[key])
      Array.isArray(err) ? errors.push(...err) : errors.push(err)
    })
    return errors
  }else{
    return payload
  }
}
