<template>
  <v-col
    cols="12"
    class="statistics-box py-0"
    :class="{ 'blocked-box': disabledView }"
  >
    <div class="d-flex align-end">
      <v-col
        cols="2"
        class="px-0"
        style="max-width: 200px"
      >
        <label>
          11(12)Страна происх.
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-0"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                @click="copyOriginCountryToAll"
              >
                Применить ко всем товарам
              </v-list-item>
              <v-list-item
                @click="copyOriginCountry"
              >
                Применить к выбранным товарам
              </v-list-item>
              <v-list-item @click="openCountries('origin_country_letter')">
                Поиск в справочнике
              </v-list-item>
              <v-list-item
                v-if="
                  customFieldChecker(
                    countries,
                    item.origin_country_letter,
                    'letterCode'
                  )
                "
                @click="item.origin_country_letter = null"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="item.origin_country_letter = ''"
              >
                Значение из справочника
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <div class="d-flex align-center">
          <v-text-field
            v-show="
              !customFieldChecker(
                countries,
                item.origin_country_letter,
                'letterCode'
              )
            "
            ref="origin_country_letter__custom"
            v-model="item.origin_country_letter"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
          <v-autocomplete
            v-show="
              customFieldChecker(
                countries,
                item.origin_country_letter,
                'letterCode'
              )
            "
            ref="origin_country_letter"
            v-model="item.origin_country_letter"
            auto-select-first
            :items="countries"
            item-text="text"
            item-value="letterCode"
            outlined
            dense
            background-color="white"
            hide-details="auto"
            class="mr-1 origin_country_letter"
            :filter="filterBySearchField"
          />
        </div>
      </v-col>

      <v-col
        class="px-1"
        cols="4"
      >
        <div class="d-flex justify-space-between align-end">
          <label class="mb-0">
            18 Вес нетто (кг)
            <input-numeric
              ref="net_weight"
              v-model="item.net_weight"
              background-color="white"
              class="mr-1 net_weight"
              @change="
                (value) => replaceFloatNumbers({ value, prop: 'net_weight' })
              "
            />
          </label>
          <span class="text-body-1 mb-2 mr-1">/</span>
          <label class="mb-0">
            (без упаковки)
            <input-numeric
              ref="net_weight_no_pack"
              v-model="item.net_weight_no_pack"
              background-color="white"
              class="net_weight_no_pack mr-1"
              @change="
                (value) =>
                  replaceFloatNumbers({ value, prop: 'net_weight_no_pack' })
              "
            />
          </label>

          <label class="mb-0">
            19 Вес брутто
            <input-numeric
              ref="gross_weight"
              v-model="item.gross_weight"
              background-color="white"
              class="gross_weight"
              @change="
                (value) => replaceFloatNumbers({ value, prop: 'gross_weight' })
              "
            />
          </label>
        </div>
      </v-col>

      <v-col style="max-width: 280px">
        <label>
          20 Доп.единицы измерения
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-0"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                @click="copyAddMeasureToAll"
              >
                Применить ко всем товарам
              </v-list-item>
              <v-list-item
                @click="copyAddMeasure"
              >
                Применить к выбранным товарам
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <div class="d-flex">
          <input-numeric
            ref="add_measure_unit_quantity"
            v-model="item.add_measure_unit_quantity"
            :disabled="!tnved || !showQuantity"
            class="add_measure_unit_quantity"
            :background-color="!showQuantity ? 'grey lighten-2' : 'white'"
            :fraction="2"
          />
          <v-autocomplete
            ref="add_measure_unit_digit"
            v-model="item.add_measure_unit_digit"
            :disabled="!tnved || !showQuantity"
            auto-select-first
            :items="measure_units"
            item-text="text"
            item-value="code"
            outlined
            dense
            :background-color="!showQuantity ? 'grey lighten-2' : 'white'"
            hide-details="auto"
            class="add_measure_unit_digit ml-2"
            :filter="filterBySearchField"
            style="width: 60%"
          />
        </div>
      </v-col>
      <v-col
        class="px-1"
        cols="2"
      >
        <label>
          21 Стоимость товара
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                tabindex="-1"
                class="mx-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                @click="copyInvoiceCurrencyToAll"
              >
                Применить ко всем товарам
              </v-list-item>
              <v-list-item
                @click="copyInvoiceCurrency"
              >
                Применить к выбранным товарам
              </v-list-item>
              <v-list-item @click="openCurrencies('invoice_currency_letter')">
                Поиск в справочнике
              </v-list-item>
              <!--  -->
              <v-list-item
                v-if="
                  !customFieldCheckerNull(
                    currencies,
                    item.invoice_currency_letter,
                    'letterCode'
                  )
                "
                @click="item.invoice_currency_letter = ''"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="item.invoice_currency_letter = null"
              >
                Значение из справочника
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <div class="d-flex">
          <v-text-field
            v-show="
              customFieldCheckerNull(
                currencies,
                item.invoice_currency_letter,
                'letterCode'
              )
            "
            ref="invoice_currency_letter__custom"
            v-model="item.invoice_currency_letter"
            v-mask="'XXX'"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            class="mr-2 currency-code"
          />
          <v-autocomplete
            v-show="
              !customFieldCheckerNull(
                currencies,
                item.invoice_currency_letter,
                'letterCode'
              )
            "
            ref="invoice_currency_letter"
            v-model="item.invoice_currency_letter"
            auto-select-first
            :items="currencies"
            item-text="text"
            item-value="letterCode"
            outlined
            dense
            background-color="white"
            hide-details="auto"
            class="mr-2 currency-code"
            :filter="filterBySearchField"
            @change="updateStatCost(null)"
          />
          <input-numeric
            ref="invoice_cost"
            v-model="item.invoice_cost"
            class="currency-value"
            background-color="white"
            @change="onInvoiceCostChange"
          />
        </div>
      </v-col>
      <v-col
        class="px-1"
        style="max-width: 150px"
      >
        <label>
          21а Стат. стоим.
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune
              </v-icon>
            </template>
            <v-list>
              <v-list-item
                v-if="disabled_statistical_cost"
                @click="disabled_statistical_cost = false"
              >
                Ручное заполнение
              </v-list-item>
              <v-list-item
                v-else
                @click="disabled_statistical_cost = true"
              >
                Отключить ручное заполнение
              </v-list-item>
              <v-list-item @click="recalculateCustomsCost">
                Пересчитать для всех товаров
              </v-list-item>
            </v-list>
          </v-menu>
          <input-numeric
            ref="statistical_cost"
            :value="item.statistical_cost"
            class="statistical_cost"
            :background-color="
              disabled_statistical_cost ? 'grey lighten-2' : 'white'
            "
            :disabled="disabled_statistical_cost"
            :fraction="2"
          />
        </label>
      </v-col>
      <v-col
        style="max-width: 70px"
        class="px-0 text-end"
      >
        <label class="green--text darken-4">
          $/кг
          <div class="transition-box__param font-weight-bold my-2 mb-3">
            <span class="green--text darken-4">{{ customsIndex }}</span>
          </div>
        </label>
      </v-col>
    </div>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import {
  getCountryCodeByName,
  getCountryNameByCode,
  getMeasureUnitShortName,
} from "@/helpers/catalogs";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {getStatisticalCost, normalizeFloat} from "@/helpers/math";
import {
  tnvedCodeChanged,
  updateGoodParameters,
  wareShipmentDateChanged,
  addPromise, recalculateStatisticCost,
} from "@/events/statistics";
import {showCatalog, catalogValueSelected} from "@/events/statistics";
import customFieldChecker from "@/mixins/custom-field-checker";
import InputNumeric from "@/components/ui/input-numeric.vue";
import highlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";

export default {
  name: "Parameters",
  components: {
    InputNumeric,
  },
  mixins: [filterBySearchFieldMixin, customFieldChecker, copyFieldMixin, highlightFieldWareSector],
  props: {
    index: {
      required: true,
      type: Number,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    shipmentDate: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    tnved: {
      required: true,
      validator: (prop) => typeof prop === "string" || prop === null,
    },
    showQuantity: {
      required: true,
      type: Boolean,
    },

  },
  data: () => ({
    disabled_statistical_cost: true,
    item: {
      id: "",
      net_weight: "",
      net_weight_no_pack: "",
      gross_weight: "",
      invoice_cost: "",
      invoice_currency_letter: "",
      add_measure_unit_quantity: "",
      add_measure_unit_digit: "",
      add_measure_unit_letter: "",
      origin_country_letter: "",
      statistical_cost: "",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      selected_wares: "statistics/getSelectedWares",
      countries: "catalogs/getNsiCountries",
      measure_units: "catalogs/getNsiMeasureUnits",
      currencies: "catalogs/getNsiCurrencies",
      rates: "currency/getRates",
      disabledView: "statistics/getVisibility"
    }),
    customsIndex() {
      const value = (this.item.statistical_cost / this.item.net_weight).toFixed(
        2
      );
      if (
        !Number.isNaN(value) &&
          !Number.isFinite(value) &&
          this.item.statistical_cost &&
          this.item.net_weight
      ) {
        return `${value}`;
      }
      return "0";
    },
  },
  watch: {
    selected_wares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    countries() {
      this.setFields();
    },
    item: {
      handler() {
        if (this.visited) this.updateEditableGoodsItem();
      },
      deep: true,
    },
  },
  created() {
    updateGoodParameters.subscribe(this.updateParameters);
    tnvedCodeChanged.subscribe(this.updateAddMeasure);
    wareShipmentDateChanged.subscribe(this.updateStatCostResolver);
    catalogValueSelected.subscribe(this.updatesResolver, [
      "ware/origin_country_letter",
      "ware/invoice_currency_letter",
    ]);
  },
  beforeDestroy() {
    updateGoodParameters.unsubscribe();
    tnvedCodeChanged.unsubscribe();
    wareShipmentDateChanged.unsubscribe();
    catalogValueSelected.unsubscribe();
  },
  methods: {
    copyOriginCountry(){
      const {keys, values} = this.getPreparedFieldsPayload(['origin_country_name', 'origin_country_letter'])
      this.copy(keys, values, 'apply', this.index)
    },
    copyOriginCountryToAll(){
      const {keys, values} = this.getPreparedFieldsPayload(['origin_country_name', 'origin_country_letter'])
      this.copyToAll(keys, values)
    },
    copyAddMeasure(){
      const {keys, values} = this.getPreparedFieldsPayload([
        'add_measure_unit_quantity','add_measure_unit_digit','add_measure_unit_letter'
      ])
      this.copy(keys, values, 'apply', this.index)
    },
    copyAddMeasureToAll(){
      const {keys, values} = this.getPreparedFieldsPayload([
        'add_measure_unit_quantity','add_measure_unit_digit','add_measure_unit_letter'
      ])
      this.copyToAll(keys, values)
    },
    copyInvoiceCurrency(){
      const {keys, values} = this.getPreparedFieldsPayload(['invoice_currency_letter'])
      this.copy(keys, values, 'apply', this.index)
    },
    copyInvoiceCurrencyToAll(){
      const {keys, values} = this.getPreparedFieldsPayload(['invoice_currency_letter'])
      this.copyToAll(keys, values)
    },
    getPreparedFieldsPayload(keysToPick = []){
      const payload = this.getFullBody()
      const prepared = payload.pick(keysToPick)
      const keys = Object.keys(prepared)
      const values = Object.values(prepared)
      return {keys, values}
    },
    getFullBody(){
      return {
        ...this.item,
        origin_country_name:
            getCountryNameByCode(
              this.countries,
              this.item.origin_country_letter
            ),
        add_measure_unit_letter:
            getMeasureUnitShortName(
              this.measure_units,
              this.item.add_measure_unit_digit
            ),
      }
    },
    recalculateCustomsCost() {
      recalculateStatisticCost.trigger()
    },
    replaceFloatNumbers({value, prop, afterPoint = null}) {
      this.item[prop] = normalizeFloat({value, afterPoint});
    },
    updatesResolver({id, code, field}) {
      if (id === this.item.id) {
        if (code && field) {
          this.$emit("update:visited", true);
          const key = field.split("/")[1];
          this.item[key] = code;
        }
      }
    },
    updateStatCostResolver({id, date}) {
      if (id === this.item.id) {
        this.updateStatCost(date);
      }
    },
    onInvoiceCostChange(value) {
      this.replaceFloatNumbers({value, prop: "invoice_cost", afterPoint: 2}); // Нормализуем значение
      this.updateStatCost(); // Обновляем стат стоимость
    },
    updateStatCost(date = null) {
      const promise = new Promise((resolve, reject) => {
        const shipmentDate = date || this.shipmentDate;
        getStatisticalCost
          .call(this, {
            date: shipmentDate,
            code: this.item.invoice_currency_letter,
            cost: this.item.invoice_cost,
          })
          .then((statCost) => {
            this.item.statistical_cost = statCost;
            return resolve(statCost);
          })
          .catch((err) => reject(err));
      });
      addPromise.trigger({id: this.selected_wares[this.index].id, promise});
      return promise;
    },
    async updateParameters({index, payload}) {
      if (index === this.index) {
        Object.keys(payload).forEach((key) => {
          if (
            ["net_weight", "net_weight_no_pack", "gross_weight"].includes(key)
          ) {
            this.replaceFloatNumbers({
              value: payload[key].toString(),
              prop: key,
            });
          } else if (key === "invoice_cost") {
            this.replaceFloatNumbers({
              value: payload[key].toString(),
              prop: key,
              afterPoint: 2,
            });
          } else if (key === "add_measure_unit_quantity") {
            this.item[key] =
                !this.tnved || !this.showQuantity ? null : payload[key];
          } else {
            this.item[key] = payload[key];
          }
        });
        await this.updateStatCost();
        this.updateEditableGoodsItem();
        this.$emit("recalculated", {index: this.index});
      }
    },
    setFields() {
      const item = this.selected_wares[this.index];

      Object.keys(item).forEach((key) => {
        if (key in this.item) {
          this.item[key] = item[key];
        }
      });
      this.setItemOriginCountry(item)
    },
    setItemOriginCountry(item){
      const {origin_country_letter, origin_country_name} = item || {};
      this.item.origin_country_letter =
          origin_country_letter ||
          getCountryCodeByName(this.countries, origin_country_letter);
      if (origin_country_name && !this.item.origin_country_letter) {
        this.item.origin_country_letter = origin_country_name;
      }
    },
    updateEditableGoodsItem() {
      const payload = this.getFullBody()
      const params = convertEmptyStringsToNull(payload);
      this.$emit("update", params);
    },
    updateAddMeasureWithTnved(code){
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("tnved/fetchAddInfoByCode", code)
          .then((res) => {
            const {add_unit_measure} = res.data;
            this.item.add_measure_unit_digit = add_unit_measure;
            if (!add_unit_measure) this.item.add_measure_unit_quantity = "";

            this.$emit("measure-changed", {
              id: this.item.id,
              value: add_unit_measure,
            });
            resolve();
          })
          .catch(() => reject());
      });
    },
    clearAddMeasure(){
      return new Promise((resolve) => {
        this.item.add_measure_unit_digit = null;
        this.item.add_measure_unit_quantity = null;
        this.$emit("measure-changed", {
          id: this.item.id,
          value: this.item.add_measure_unit_digit,
        });
        resolve();
      });
    },
    updateAddMeasure({id, code}) {
      if (id === this.selected_wares[this.index].id) {
        if (code) {
          const promise = this.updateAddMeasureWithTnved(code)
          addPromise.trigger({
            id: this.selected_wares[this.index].id,
            promise,
          });
        } else {
          const promise = this.clearAddMeasure()
          addPromise.trigger({
            id: this.selected_wares[this.index].id,
            promise,
          });
        }
      }
    },
    openCountries(field) {
      showCatalog.trigger({
        id: this.item.id,
        field: `ware/${field}`,
        type: "countries",
        search: this.item[field],
      });
    },
    openCurrencies(field) {
      showCatalog.trigger({
        id: this.item.id,
        field: `ware/${field}`,
        type: "currencies",
        search: this.item[field],
      });
    },
  },
};
</script>
<style scoped>
.currency-code {
  max-width: 85px;
}
</style>
