<template>
  <section
    class="pb-16 mx-auto sd-main"
    style="max-width: 1440px"
  >
    <pinned-template :tabs="tabs" />
    <statistics-toolbox />
    <statistics-main-info />
    <statistics-contragents />
    <statistics-declarant />
    <statistics-documents v-if="!pinned.includes('documents')" />
    <statistics-goods-character v-if="!pinned.includes('goods-character')" />
    <statistics-goods />

    <catalogs />
    <shared-buffer />
    <statistics-copy-field-modal />
    <copy-field-to-all-modal />
    <side-menu :items="menuItems" />
    <distinct-division-notification />
  </section>
</template>
<script>
import PinnedTemplate from "@/components/shared/pinned-template.vue";

import StatisticsToolbox from "@/components/statistics/statistics-toolbox.vue";
import StatisticsMainInfo from "@/components/statistics/statistics-main-info.vue";
import StatisticsContragents from "@/components/statistics/statistics-contragents.vue";
import StatisticsDeclarant from "@/components/statistics/statistics-declarant.vue";
import StatisticsDocuments from "@/components/statistics/statistics-documents.vue";
import StatisticsGoods from "@/components/statistics/statistics-goods.vue";
import StatisticsGoodsCharacter from "@/components/statistics/goods-character.vue";
import Catalogs from "@/components/catalogs/modals/resolver.vue";
import {mapGetters} from "vuex";
import offsetMixin from "@/mixins/offset.mixin.js";
import SharedBuffer from "@/components/shared/shared-buffer.vue";
import SideMenu from "@/components/shared/side-menu.vue";
import WaresScroll from "@/components/shared/wares-scroll.vue";
import DistinctDivisionNotification from "@/components/documents/warnings/distinct-division-notification.vue";
import {setDeclarationTitle} from "@/helpers/text";
import CopyFieldToAllModal from "@/components/documents/copy-field-to-all-modal.vue";
import StatisticsCopyFieldModal from "@/components/statistics/goods/statistics-copy-field-modal.vue";

export default {
  components: {
    StatisticsCopyFieldModal,
    CopyFieldToAllModal,
    DistinctDivisionNotification,
    SideMenu,
    SharedBuffer,
    PinnedTemplate,
    StatisticsToolbox,
    StatisticsMainInfo,
    StatisticsContragents,
    StatisticsDeclarant,
    StatisticsDocuments,
    StatisticsGoodsCharacter,
    StatisticsGoods,
    Catalogs,
  },
  mixins: [offsetMixin],
  provide: {
    rootNamespace: 'statistics'
  },
  data: () => ({
    tabs: [
      {
        name: "documents",
        component: () => import("@/components/statistics/statistics-documents.vue"),
      },
      {
        name: "goods-character",
        component: () => import("@/components/statistics/goods-character.vue"),
      },
      {
        name: "controls",
        component: () =>
          import("@/components/statistics/controls/common-errors.vue"),
      },
    ],
    menuItems: [
      {
        name: "Основная информация",
        id: "sd-main-info"
      },
      {
        name: "Резидент",
        id: "statistics-importer-block"
      },
      {
        name: "Контрагент",
        id: "statistics-contragent-block"
      },
      {
        name: "Информация о сделке",
        id: "statistics-deal-block"
      },
      {
        name: "Декларант",
        id: "statistics-declarant-block"
      },
      {
        name: "Документы",
        id: "statistics-documents-block"
      },
      {
        name: "Общие характеристики товара",
        id: "statistics-character-block"
      },
      {
        name: "Товары",
        id: "statistics-goods-block",
        component: WaresScroll,
      },
    ]
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
    }),
  },
  watch: {
    selected: {
      handler(statistics) {
        const {remark, out_number: outNumber} = statistics?.about_declaration ?? {}
        setDeclarationTitle({
          outNumber,
          remark,
          prefix: "СД"
        })
      },
      deep: true,
    },
  },
  created() {
    this.setExtraTnvedOptions()
    this.$store.dispatch("catalogs/fetchAll");
    this.$store.dispatch("currency/fetchCurrentRates");
    const id = this.$route.params.id;
    this.$store.dispatch("statistics/getDocumentById", id).catch((err) => {
      if (err.response.status === 403) {
        this.$router.push("/403");
      }
    });
  },
  beforeDestroy() {
    this.$store.commit("statistics/UNSET_CONTROL"); // Очищаем контроли
    this.$store.commit("statistics/REMOVE_REQUEST"); // Очищаем контроли
    document.title = "Веб-Декларант";
  },
  methods:{
    setExtraTnvedOptions(){
      this.$store.dispatch('catalogs/fetchExtraTnvedOptions').then((res) => {
        const {names_11, names_12, names_13, names_14} = res.data
        this.$store.commit('statistics/SET_EXTRA_TNVED_OPTIONS', {
          firstOptions:names_11,
          secondOptions:names_12,
          thirdOptions:names_13,
          fourthOptions:names_14
        })
      })
    }
  }
};
</script>

<style scoped>
.sd-main {
  transition: 0.3s ease-in-out;
  transition-property: margin-top;
}
</style>
