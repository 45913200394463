<template>
  <UploadExcelModal
    ref="uploadExcelModal"
    :disabled="disabled"
    :loading="loading"
    @submit="onSubmit"
  >
    <UploadExcelParams
      v-model="filteredParams"
    />
    <v-file-input
      v-model="file"
      dense
      outlined
      color="#32a1af"
      solo
      flat
      clearable
      accept=".xls, .xlsx"
      placeholder="Выберите файл"
    />
  </UploadExcelModal>
</template>
<script>

import {OVERWRITE, UNKNOWN_COUNTRY, JOIN_ARTICLE_DESCRIPTION} from "@/constants/upload-excel-params";
import uploadExcelMixin from "@/mixins/upload-excel.mixin";

function initialState() {
  return {
    loading: false,
    file:null,
    params:[
      JOIN_ARTICLE_DESCRIPTION,
      OVERWRITE,
      UNKNOWN_COUNTRY,
    ],
  }
}
export default {
  mixins:[uploadExcelMixin],
  data(){
    return initialState()
  },
  methods: {
    resetState() {
      Object.assign(this.$data, initialState())
    },
    onSubmit() {
      const formData = new FormData()
      formData.append('excel', this.file)
      const payload = {
        id: this.selected.id,
        formData,
        ...this.preparedParams(),
      }
      this.uploadExcel(payload).then(() => {
        this.$refs.uploadExcelModal.close()
        this.resetState()
      })
    }
  }
}
</script>
