<template>
  <v-col
    cols="12"
    class="goods-description__block"
  >
    <v-tabs v-model="tab">
      <v-tab tabindex="-1">
        9 Описание товаров
      </v-tab>
      <v-tab
        tabindex="-1"
        class="ware-show-list-tab"
      >
        Добавить товары списком
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="tab-grey">
        <v-textarea
          v-show="showTop"
          placeholder="Значения из списка"
          :rows="topRows"
          loading="false"
          no-resize
          :value="waresListText"
          full-width
          background-color="white"
          hide-details="true"
          class="textarea-top"
          readonly
          @click="tab = 1"
        />
        <div class="goods-description__text-wrapper">
          <v-textarea
            v-model="item.description"
            placeholder="Описание"
            :counter="1000"
            :counter-value="total"
            :rows="bottomRows"
            auto-grow
            no-resize
            loading="false"
            full-width
            background-color="white"
            hide-details="auto"
            class="description-text"
            :class="showTop ? 'textarea-bottom-dashed' : 'textarea-bottom-solid'"
            @input="(nv) => $emit('input', nv)"
          />
          <div
            v-show="!add_goods_by_list"
            class="goods-description__action"
          >
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  tabindex="-1"
                  min-width="36px"
                  max-width="36px"
                  color="#5CB7B1"
                  v-on="on"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item

                  @click="copyToAll('description', item.description)"
                >
                  <v-list-item-title>Применить ко всем</v-list-item-title>
                </v-list-item>
                <v-list-item

                  @click="copy('description', item.description, 'apply', index)"
                >
                  <v-list-item-title>Применить к выбранным товарам</v-list-item-title>
                </v-list-item>
                <v-list-item @click="googleImage(item.description)">
                  <v-list-item-title>
                    <v-icon>mdi-magnify</v-icon>
                    Поиск в  Google
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item eager>
        <table-goods-list
          :ware-details="wareDetails"
          :show-quantity="showQuantity"
          :item="item"
          :index="index"
          @update="(nv) => (waresListText = nv)"
        />
      </v-tab-item>
    </v-tabs-items>

    <Portal :to="'tnved-extra-modal-'+item.id">
      <div
        v-if="combinedDescription"
        class="rounded mb-2 teal lighten-1 white--text py-4 px-6"
      >
        {{ combinedDescription }}
      </div>
    </Portal>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import TableGoodsList from "./table-goods-list.vue";
import {googleImage} from '@/helpers/text'
import copyFieldMixin from "@/mixins/copy-field.mixin";
export default {
  name: "GoodsDescription",
  components:{ TableGoodsList},
  mixins:[copyFieldMixin],
  props: {
    index: {
      type: Number,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    wareDetails: {
      type: Array,
      required: true,
    },
    showQuantity:{
      type: Boolean,
      required: true,
    }
  },
  data: () => ({
    waresListText: "",
    tab: 0,
    selectedTab: {},
    add_goods_by_list: false,
  }),
  computed: {
    // Верхняя часть описания заполняется через "Список"
    ...mapGetters({
      selected: "statistics/getSelected",
    }),
    showTop() {
      return !!this.waresListText.length || false;
    },
    // Cчитаем ко-во строк в верхнем блоке и нижнем, чтобы в сумме была 100 символов. Примерно 125 на строку.
    topRows() {
      return Math.ceil(this.waresListText.length / 125) || 0;
    },
    bottomRows() {
      return 2 - this.topRows;
    },
    combinedDescription(){
      return [this.item?.description, this.waresListText].join('; ').trim()
    }
  },
  methods: {
    googleImage,
    total() {
      return [this.item?.description, this.waresListText].filter(i => !['', null].includes(i)).join('; ').length
    },
  },
};
</script>
<style scoped>
.goods-description__block, .goods-description__text-wrapper {
  position: relative;
}
.goods-description__action {
  position: absolute;
  right: 1%;
  top: 20px;
  z-index: 2;
}
.textarea-top {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-bottom: transparent;
  border-radius: 4px 4px 0 0;
  margin: 10px 0 0 0;
  padding: 10px 50px 10px 10px;
}
.textarea-bottom-dashed {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-top: 1px dashed rgba(0, 0, 0, 0.8);
  border-radius: 0 0 4px 4px;
  margin: 0;
  padding: 10px 50px 10px 10px;
}
.textarea-bottom-solid {
  border: 1px solid rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  margin: 10px 0 0 0;
  padding: 10px 50px 10px 10px;
}
</style>
