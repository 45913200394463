<template>
  <div
    :id="block_id"
    v-click-outside="triggerOnFocus"
    class="document__row"
    :draggable="draggable"
    @dragstart="startDrag($event, document)"
    @mouseup="mouseUp"
  >
    <div class="document__select ">
      <v-checkbox
        tabindex="-1"
        dense
        class="pa-0 ml-1"
        hide-details
        color="#5CB7B1"
        :value="selectedDocs.includes(item.id)"
        @change="updateSelected"
      />
    </div>

    <div class="document__sort">
      <div
        class="allow-drag"
        @mousedown="mouseDown($event)"
      >
        {{ document.sort_index }}
      </div>
    </div>

    <div class="document__code">
      <v-text-field
        v-show="customFieldChecker(doc_types, document.doc_code, 'code')"
        ref="doc_code"
        v-model="document.doc_code"
        v-mask="'XXXXX'"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
      <v-tooltip
        bottom
        max-width="300px"
        :disabled="!document.doc_code || hideTooltips"
      >
        <template #activator="{ on }">
          <v-autocomplete
            v-show="!customFieldChecker(doc_types, document.doc_code, 'code')"
            ref="doc_code__custom"
            v-model="document.doc_code"
            auto-select-first
            :items="doc_types"
            item-text="code"
            :filter="filterBySearchField"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            v-on="on"
          />
        </template>
        <span>{{ docName }}</span>
      </v-tooltip>

      <!-- <v-btn
        tabindex="-1"
        min-width="36px"
        max-width="36px"
        color="#5CB7B1"
        class="ml-1 elevation-0"
        @click="showDocuments"
      >
        <v-icon>
          mdi-format-list-bulleted
        </v-icon>
      </v-btn> -->
      <v-menu offset-x>
        <template #activator="{ on }">
          <v-btn
            tabindex="-1"
            min-width="36px"
            max-width="36px"
            color="#5CB7B1"
            class="ml-1 elevation-0"
            v-on="on"
          >
            <v-icon>
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            tag="button"
            @click="showDocuments"
          >
            Показать справочник
          </v-list-item>
          <v-list-item
            v-if="!customFieldChecker(doc_types, document.doc_code, 'code')"
            tag="button"
            @click="document.doc_code = ''"
          >
            Произвольное значение
          </v-list-item>
          <v-list-item
            v-else
            tag="button"
            @click="document.doc_code = null"
          >
            Значение из справочника
          </v-list-item>
        </v-list>
      </v-menu>
    </div>


    <div
      class="document__number"
    >
      <v-text-field
        v-if="!requiresMask"
        key="no-mask"
        ref="doc_number"
        v-model="document.doc_number"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
      <v-text-field
        v-else
        :key="document.doc_code"
        ref="doc_number"
        v-model="document.doc_number"
        v-mask="mask"
        :disabled="document.deal_no_required"
        :value="document.doc_number"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </div>

    <div class="document__date">
      <custom-date-picker
        ref="doc_date"
        v-model="document.doc_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
      />
    </div>


    <div class="document__range">
      <v-text-field
        ref="ware_range"
        v-model="range"
        placeholder="Все товары"
        outlined
        dense
        hide-details="auto"
        background-color="white"
        @change="onRangeChange"
      />
    </div>
    <v-btn
      v-if="index === presentedDocuments.length - 1"
      class="document-add elevation-0"
      color="transparent"
      min-width="36px"
      max-width="36px"
      @click="addNewDoc"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <div>
      <v-btn
        v-if="document.doc_code === '03031'"
        tabindex="-1"
        text
        small
        @click="setNoRegistration"
      >
        <v-icon
          small
          class="mr-2"
        >
          {{ document.deal_no_required ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
        </v-icon>
        Регистрация сделки не требуется
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { deleteNulls } from "@/helpers/inputs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { convertEmptyStringsToNull } from "@/helpers/objects";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { isGoodsRangeValid, isCorrectStartEnd, trimRange } from "@/helpers/inputs";
import { showCatalog } from "@/events/statistics";
import { documentTypeSelected } from "@/events/statistics";
import CustomDatePicker from "@/components/shared/custom-date-picker"
import {DEAL_REGISTRATION_NOT_REQUIRED} from '@/constants'
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
export default {
  components:{
    CustomDatePicker
  },
  mixins: [blockAutoUpdate, filterBySearchFieldMixin, highlightFieldsChild],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    draggable: {
      required: true,
      type: Boolean,
    },
    forSingleItem: {
      required: true,
      type: Boolean,
    },
    hideTooltips: {
      required: true,
      type: Boolean
    },
    selectedDocs:{
      required:true,
      type:Array
    },
    presentedDocuments:{
      required: true,
      type: Array
    },
  },
  data: () => ({
    selected: null,
    mousePressed: false,
    isIntersecting: false,
    parent: null,
    block_id: null,
    menu: false,
    document: {
      id: "",
      declaration_id: "",
      doc_code: "",
      doc_number: "",
      doc_date: "",
      ware_range: "",
      sort_index: "",
      updated: "",
      deal_no_required:null
    },
    range: "",
    noRegistration: true,
    key:'',
    noRegistrationText:DEAL_REGISTRATION_NOT_REQUIRED
  }),

  computed: {
    ...mapGetters({
      doc_types: "catalogs/getNsiTypesOfDocument",
    }),
    docName() {
      const { name } =
        this.doc_types.find((i) => i.code === this.document.doc_code) || {};
      return name || "";
    },
    requiresMask(){
      return ['03031','04141'].includes(this.document.doc_code)
    },
    mask(){
      if(this.document.doc_code === '03031'){
        return '######/######/#####'
      }else if(this.document.doc_code === '04141'){
        return {
          mask:'ESF-XXXXXXXXXXXX-########-########',
          tokens:{
            '#': {pattern: /\d/},
            'X': {
              pattern: /[0-9a-zA-Z]/,
              transform: v => v.toLocaleUpperCase()
            },
            'ESF': {
              pattern: /(ESF)/,
            }
          }
        }
      }else {
        return '!'
      }
    }
  },
  watch: {
    "document.doc_code":{
      handler(nv){
        if(
          (nv === '03031' && !this.checkDocumentMask(this.document.doc_number) && !this.document.deal_no_required) ||
          (nv !== '03031' && this.document.doc_number === DEAL_REGISTRATION_NOT_REQUIRED) //Если был 03031 то очищаем поле в случае "не трубует регистрации"
        ){
          this.document.doc_number = ''
          this.document.deal_no_required = null
        }
      }
    },
    range(nv) {
      this.validateRange(nv.replace(".", ","));
    },
    item: {
      handler(newVal) {
        this.block_id = `statistics-present-document-${this.item.id}`;
        for (let key in newVal) {
          if (key in this.document) {
            this.document[key] = newVal[key];
          }
        }
        this.range = newVal?.ware_range ?? "";
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    documentTypeSelected.subscribe(this.checkAndUpdateDocType);
    const collection = [...document.getElementsByClassName("drop-zone")];
    const [parent] = collection;
    this.parent = parent;
  },
  beforeDestroy() {
    documentTypeSelected.unsubscribe();
  },
  methods: {
    checkDocumentMask(value){
      const regex = /([0-9]{6}\/[0-9]{6}\/[0-9]{5})/g
      return new RegExp(regex).test(value)
    },
    onRangeChange(value){
      this.range = trimRange(value)
    },
    addNewDoc(){
      this.$emit('addNewDoc')
    },
    customFieldChecker(items, field, param) {
      if(field === null){
        return false
      }else if(field === ''){
        return true
      }else{
        var positiveArr = items.filter(function(i) {
          return i[param] === field;
        })
        if(positiveArr.length){
          return false
        }else{
          return true
        }
      }
    },
    updateSelected(){
      this.$emit('select', this.item.id)
    },
    setNoRegistration(){
      const newValue = !this.document.deal_no_required
      this.document.deal_no_required = newValue
      if(newValue === true){
        this.document.doc_number = DEAL_REGISTRATION_NOT_REQUIRED
      }else{
        this.document.doc_number = null
      }
      this.document.doc_date = null
      this.fieldsBeenVisitedByUser()
      this.hasChanges = true
    },
    validateRange(nv) {
      const result = isGoodsRangeValid(nv);
      if (result !== false) {
        this.document.ware_range = result;
        this.range = result;
      } else {
        this.$nextTick(() => {
          this.range = this.document.ware_range;
        });
      }
    },
    startDrag(evt, document) {
      if(this.hasChanges && this.fields_been_visited){
        return this.$snackbar({text:"Сохраните изменения перед сортировкой.",color:"orange", top:false, right: false});
      }
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("document", document.id);
      // console.log('get', evt.dataTransfer.getData("document"))
    },
    mouseUp() {
      this.$emit("update:draggable", false);
    },
    mouseDown(evt) {
      // Перетаскивание только в режиме просмотра всех товаров
      if (
        [...evt.target.classList].includes("allow-drag") &&
        !this.forSingleItem
      ) {
        this.$emit("update:draggable", true);
      }
    },
    deleteNulls,
    deleteDocument() {
      const { id } = this.item;
      const index = this.index;
      this.$emit("delete", {
        id,
        index,
      });
    },
    uploadData() {
      if (!isCorrectStartEnd(this.document.ware_range)) {
        this.processing = false //  Если вышли из блока когда не верный диапазон то надо разрешить отправку повторно
        return this.$snackbar({
          text: "Неверное начало и конец диапазона товаров",
          color: "red",
          top:false, right: false
        });

      }
      const index = this.index;
      const payload = convertEmptyStringsToNull(this.document);
      return this.$store.dispatch("statistics/updateDocument", {
        index,
        payload,
      });
    },
    showDocuments() {
      showCatalog.trigger({
        type: "doctypes",
        id: this.document.id,
        search: this.document.doc_code,
      });
    },
    checkAndUpdateDocType({ id, code }) {
      if (this.document.id === id) {
        this.document.doc_code = code;
        this.fieldsBeenVisitedByUser();
      }
    },
  },
};
</script>
<style scoped>
.document__row{
    display: flex;
    align-items: center;
}
.document__select{
    display: flex;
    justify-content: center;
    align-items: center;
}
.document__sort{
  width: 60px !important;
  padding: 0 10px 0 0;
}

.document__code{
  width: 170px !important;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
}
.document__number{
  width: 310px !important;
  padding: 0 10px 0 0;
}
.document__date{
  width: 160px !important;
}
.document__range{
  width: 280px !important;
  padding: 0 10px;
}

.h-full {
  height: 100%;
}

.document-row:last-child {
  padding: 0 10px 4px 0 !important;
}
.allow-drag{
  cursor: pointer!important;
}
</style>
