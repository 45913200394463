<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          class="statistics-box pa-4"
          :class="{ 'blocked-box': blocked_visibility }"
        >
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col
                  cols="9"
                  class="pb-0"
                >
                  <div class="font-weight-bold d-flex align-center mt-2">
                    Общие характеристики товара
                  </div>
                </v-col>
                <v-col
                  cols="3"
                  class="justify-end d-flex"
                  style="z-index: 2"
                >
                  <v-text-field
                    v-model="searchValue"
                    outlined
                    tabindex="-1"
                    dense
                    clearable
                    hide-details="auto"
                    placeholder="Поиск по товарам"
                    background-color="white"
                    class="rounded-r-0"
                    @keypress.enter="search"
                    @click:clear="clearSearch"
                  />
                  <v-btn
                    min-width="40px"
                    max-width="40px"
                    height="40px"
                    dark
                    class="elevation-0 rounded-l-0"
                    @click="search"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                  <v-btn
                    tabindex="-1"
                    min-width="40px"
                    max-width="40px"
                    height="40px"
                    elevation="0"
                    color="#5CB7B1"
                    class="ml-2"
                    @click="togglePin"
                  >
                    <v-icon>
                      {{ pinned.includes(pinName) ? "mdi-pin-off" : "mdi-pin" }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row
                :id="block_id"
                v-click-outside="triggerOnFocus"
              >
                <v-col cols="12">
                  <div class="d-flex justify-space-between align-baseline">
                    <!--  Левая часть -->
                    <div class="mr-4 mt-0">
                      <div class="d-flex align-end">
                        <div style="width: 120px">
                          <label
                            class="grey--text text--darken-2 font-weight-medium"
                          >
                            8. Валюта
                            <v-menu offset-x>
                              <template #activator="{ on, attrs }">
                                <v-icon
                                  tabindex="-1"
                                  class="mx-1"
                                  small
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                >mdi-tune
                                </v-icon>
                              </template>
                              <v-list>
                                <v-list-item @click="openCurrencies">
                                  Поиск в справочнике
                                </v-list-item>
                                <v-list-item
                                  v-if="
                                    !customFieldCheckerNull(
                                      currencies,
                                      deal_details_json.invoice_currency_letter,
                                      'letterCode'
                                    )
                                  "
                                  @click="
                                    deal_details_json.invoice_currency_letter =
                                      ''
                                  "
                                >
                                  Произвольное значение
                                </v-list-item>
                                <v-list-item
                                  v-else
                                  @click="
                                    deal_details_json.invoice_currency_letter =
                                      null
                                  "
                                >
                                  Значение из справочника
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </label>
                          <v-text-field
                            v-show="
                              customFieldCheckerNull(
                                currencies,
                                deal_details_json.invoice_currency_letter,
                                'letterCode'
                              )
                            "
                            ref="invoice_currency_letter__custom"
                            v-model="deal_details_json.invoice_currency_letter"
                            v-mask="'XXX'"
                            full-width
                            outlined
                            dense
                            hide-details="auto"
                            background-color="white"
                          />
                          <v-autocomplete
                            v-show="
                              !customFieldCheckerNull(
                                currencies,
                                deal_details_json.invoice_currency_letter,
                                'letterCode'
                              )
                            "
                            ref="invoice_currency_letter"
                            v-model="deal_details_json.invoice_currency_letter"
                            auto-select-first
                            :items="currencies"
                            item-text="letterCode"
                            item-value="letterCode"
                            outlined
                            dense
                            hide-details="auto"
                            background-color="white"
                            :filter="filterBySearchField"
                          />
                        </div>
                        <div
                          style="width: 150px"
                          class="ml-2"
                        >
                          <label>
                            Общая сумма
                            <input-numeric
                              ref="invoice_cost"
                              :value="deal_details_json.invoice_cost"
                              class="mr-1"
                              @input="
                                (v) =>
                                  (deal_details_json.invoice_cost = v.replace(
                                    ',',
                                    '.'
                                  ))
                              "
                              @change="onInvoiceCostChange"
                            />
                          </label>
                        </div>
                        <span class="text-body-1 mb-2 mx-1">/</span>
                        <input-numeric
                          v-model="invoiceCostSum"
                          style="width: 150px"
                          class="mr-1"
                          outlined
                          dense
                          hide-details="auto"
                          disabled
                        />
                        <v-menu offset-x>
                          <template #activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              tabindex="-1"
                              max-width="40px"
                              min-width="40px"
                              height="40px"
                              class="ml-1"
                              color="#5CB7B1"
                              v-on="on"
                            >
                              <v-icon>mdi-format-list-bulleted</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              @click="recalculateCost"
                            >
                              Пересчитать общую сумму как сумму всех товаров
                            </v-list-item>
                            <v-list-item
                              :disabled="isRecalculateAllowed"
                              @click="recalculateInBYN"
                            >
                              Пересчитать все суммы в белорусские рубли
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                    <!--  Правая часть -->
                    <div class="d-flex align-end">
                      <label
                        class="grey--text text--darken-2 font-weight-medium"
                      >
                        Стат.стоимость
                        <input-numeric
                          v-model="statCostSum"
                          outlined
                          dense
                          hide-details="auto"
                          disabled
                        />
                      </label>
                      <v-btn
                        tabindex="-1"
                        max-width="40px"
                        min-width="40px"
                        height="40px"
                        class="ml-2 mb-1"
                        color="#5CB7B1"
                        @click="recalculateStatCost"
                      >
                        <v-icon>mdi-calculator</v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex align-center">
                      <label
                        class="ml-4 grey--text text--darken-2 font-weight-medium"
                      >
                        Вес нетто (кг)
                        <v-menu offset-x>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              tabindex="-1"
                              class="mx-1"
                              small
                              dense
                              v-bind="attrs"
                              v-on="on"
                            >mdi-tune
                            </v-icon>
                          </template>
                          <v-list>
                            <v-list-item
                              button
                              @click="fillNetWeightNoPackFromNetWeight"
                            >
                              Нетто без упаковки = нетто
                            </v-list-item>
                            <v-list-item
                              button
                              @click="
                                recalculateWeight('netWeightNoPack', 'нетто')
                              "
                            >
                              Нетто пропорционально нетто без упаковки
                            </v-list-item>
                            <v-list-item
                              button
                              @click="recalculateWeight('grossWeight', 'нетто')"
                            >
                              Нетто пропорционально брутто
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <input-numeric
                          v-model="netWeightSum"
                          class="mr-1"
                          disabled
                        />
                      </label>
                      <span class="text-body-1 mt-4 mx-1">/</span>
                      <label>
                        (без упаковки)
                        <input-numeric
                          v-model="netWeightNoPackSum"
                          disabled
                        />
                      </label>
                    </div>

                    <label
                      class="ml-2 mr-2 grey--text text--darken-2 font-weight-medium"
                    >
                      Вес брутто (кг)
                      <v-menu offset-x>
                        <template #activator="{ on, attrs }">
                          <v-icon
                            tabindex="-1"
                            class="mx-1"
                            small
                            dense
                            v-bind="attrs"
                            v-on="on"
                          >mdi-tune
                          </v-icon>
                        </template>
                        <v-list>
                          <v-list-item
                            button
                            @click="recalculateWeight('netWeight', 'брутто')"
                          >
                            Брутто пропорционально нетто
                          </v-list-item>
                          <v-list-item
                            button
                            @click="
                              recalculateWeight('packageWeight', 'упаковки')
                            "
                          >
                            Добавить вес упаковки
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <div class="d-flex align-center">
                        <input-numeric
                          v-model="grossWeightSum"
                          class="mr-1"
                          outlined
                          dense
                          hide-details="auto"

                          disabled
                        />
                      </div>
                    </label>
                    <label>
                      Доп.единицы
                      <div class="d-flex align-center">
                        <v-tooltip
                          bottom
                          :disabled="!addMeasureSum"
                        >
                          <template #activator="{ on, attrs }">
                            <v-text-field
                              v-model="addMeasureSum"
                              v-bind="attrs"
                              outlined
                              dense
                              hide-details="auto"
                              background-color="#E1E1E1"
                              readonly
                              class="v-input--is-disabled"
                              v-on="on"
                            />
                          </template>
                          <div>
                            <span
                              v-for="(value, key) in addMeasureSumObject"
                              :key="key"
                            >
                              {{ value }} - {{ key }} <br>
                            </span>
                          </div>
                        </v-tooltip>
                      </div>
                    </label>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <component
      :is="activeComponent"
      :params="params"
      :show.sync="showComponent"
      @perform="resolveAction"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { showCatalog, catalogValueSelected, recalculateStatisticCost } from "@/events/statistics";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import customFieldChecker from "@/mixins/custom-field-checker";
import blockVisibility from "@/mixins/block-visibility";
import { normalizeFloat } from "@/helpers/math";
import { deal_details as onDealDetails } from "@/events/statistics/control";
import { highlightField } from "@/helpers/control";
import {applyToWares, swipeToPinnedComponent} from "@/events/common";
import InputNumeric from "@/components/ui/input-numeric.vue";

export default {
  components: {
    InputNumeric,
  },
  mixins: [
    filterBySearchFieldMixin,
    blockAutoUpdate,
    customFieldChecker,
    blockVisibility,
  ],
  data: () => ({
    pinName: "goods-character",
    block_id: "statistics-character-block",
    deal_details_json: {
      invoice_currency_letter: "",
      invoice_cost: "",
    },
    activeComponent: null,
    showComponent: false,
    params: {},
    searchValue: "",
    actions: [
      {
        action: "RecalculateCost",
        component: () =>
          import("@/components/statistics/specifications/recalculate-cost.vue"),
      },
      {
        action: "recalculateInBYN",
        component: () =>
          import("@/components/statistics/specifications/recalculateinvoicecostbyn.vue"),
      },
      {
        action: "RecalculateStatCost",
        component: () =>
          import(
            "@/components/statistics/specifications/recalculate-stat-cost.vue"
          ),
      },
      {
        action: "RecalculateWeight",
        component: () =>
          import(
            "@/components/statistics/specifications/recalculate-weight.vue"
          ),
      },
    ],
  }),
  computed: {
    ...mapGetters({
      currencies: "catalogs/getNsiCurrencies",
      selected: "statistics/getSelected",
      wares: "statistics/getSelectedWares",
      pinned: "ui/getPinned",
    }),
    isRecalculateAllowed(){
      return !["51","52","53","55"].includes(this.selected?.deal_details?.deal_details_json?.deal_feature_code)
    },
    netWeightSum() {
      return this.getTotal("net_weight");
    },
    netWeightNoPackSum() {
      return this.getTotal("net_weight_no_pack");
    },
    grossWeightSum() {
      return this.getTotal("gross_weight");
    },
    invoiceCostSum() {
      return this.getTotal("invoice_cost");
    },
    statCostSum() {
      return this.getTotal("statistical_cost");
    },
    addMeasureSum() {
      return Object.keys(this.addMeasureSumObject)
        .map((key) => `${this.addMeasureSumObject[key]} ${key};`)
        .join("");
    },
    addMeasureSumObject() {
      return this.wares.reduce((acc, current) => {
        const { add_measure_unit_letter, add_measure_unit_quantity = 0 } =
          current;
        if (add_measure_unit_letter !== null) {
          const prev = acc[add_measure_unit_letter] ?? 0;
          return {
            ...acc,
            [add_measure_unit_letter]: prev + add_measure_unit_quantity,
          };
        }
        return acc;
      }, {});
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    catalogValueSelected.subscribe(this.updatesResolver, [
      "deal_details_json.invoice_currency_letter",
    ]);
    onDealDetails.subscribe(this.onControl);
    recalculateStatisticCost.subscribe(this.recalculateStatCost)
    applyToWares.subscribe(this.onApplyToWares)
  },
  beforeDestroy() {
    catalogValueSelected.unsubscribe();
    onDealDetails.unsubscribe();
    recalculateStatisticCost.unsubscribe()
    applyToWares.unsubscribe()
  },
  methods: {
    onApplyToWares(){
      if (this.searchValue){
        this.search()
      }
    },
    search() {
      this.$store
        .dispatch("statistics/searchInWares", {
          id: this.selected.id,
          value: this.searchValue,
        })
        .catch(() => {
          this.$snackbar({
            text: "Не удалось выполнить поиск",
            top: false,
            color: "red",
          });
        });
    },
    clearSearch() {
      this.$store
        .dispatch("statistics/fetchGoods", this.selected.id)
        .then((res) => {
          this.$store.commit(
            "statistics/REPLACE_GOODS_ITEM_IN_DECLARATION",
            res.data
          );
        })
        .catch(() => {
          this.$snackbar({
            text: "Не удалось загрузить товары",
            top: false,
            color: "red",
          });
        });
    },
    highlightField,
    resolveAction({ type }) {
      if (type === "invoice_cost") {
        this.updateInvoiceCost();
      }
    },
    onControl(data) {
      if (this.pinned.includes(this.pinName)) {
        const { path } = data;
        const parts = path.split(".");
        const code = parts[parts.length - 1];
        if (["invoice_cost", "invoice_currency_letter"].includes(code)) {
          swipeToPinnedComponent.trigger({ pin: this.pinName });
          this.highlightField(data);
        }
      } else {
        this.highlightField(data);
      }
    },
    onInvoiceCostChange(value) {
      this.deal_details_json.invoice_cost = normalizeFloat({
        value,
        afterPoint: 2,
      });
    },
    getTotal(field) {
      if (this.wares) {
        const value = this.wares.reduce((acc, current) => {
          return acc + (current[field] || 0);
        }, 0);
        return normalizeFloat({ value });
      }
      return "";
    },
    togglePin() {
      this.$store.commit("ui/TOGGLE_PIN", this.pinName);
    },
    updateInvoiceCost() {
      this.deal_details_json.invoice_cost = this.invoiceCostSum;
      this.triggerOnChange();
      this.uploadData()
        .then(() => {
          this.$snackbar({
            text: "Стоимость обновлена",
            color: "green",
            top: false,
            right: false,
          });
          this.showComponent = false;
        })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
        });
    },
    openCurrencies() {
      showCatalog.trigger({
        type: "currencies",
        search: this.deal_details_json.invoice_currency_letter,
        field: "deal_details_json.invoice_currency_letter",
      });
    },
    updatesResolver({ code, field }) {
      if (code && field) {
        const [parent, child] = field.split(".");
        this[parent][child] = code;
        this.fieldsBeenVisitedByUser();
        this.triggerOnChange();
      }
    },
    setFields() {
      const { deal_details: { deal_details_json = {} } = {} } = this.selected;
      Object.keys(this.deal_details_json).forEach((key) => {
        if (key in deal_details_json) {
          this.deal_details_json[key] = deal_details_json[key];
        }
      });
      this.setHasChangesFalse();
    },
    uploadData() {
      // Обновляем только часть полей из блока сделки
      const { invoice_currency_letter, invoice_cost } = this.deal_details_json;
      const payload = {
        invoice_currency_letter,
        invoice_cost,
      };
      const deal_details_json = this.convertEmptyStringsToNull(payload);
      return this.$store.dispatch("statistics/updateDeal", deal_details_json);
    },
    // подсчеты нетто
    fillNetWeightNoPackFromNetWeight() {
      this.$store
        .dispatch(
          "statistics/fillNetWeightNoPackFromNetWeight",
          this.selected.id
        )
        .then(() => {
          this.$snackbar({
            text: "Вес обновлен",
            color: "green",
            top: false,
            right: false,
          });
        })
        .catch(() => {
          this.$snackbar({
            text: "Ошибка",
            color: "red",
            top: false,
            right: false,
          });
        });
    },
    recalculateInBYN(){
      const action = this.actions.find(
        (i) => i.action === "recalculateInBYN"
      );
      this.activeComponent = action.component || null;
      this.showComponent = true;
    },
    recalculateStatCost() {
      const action = this.actions.find(
        (i) => i.action === "RecalculateStatCost"
      );
      this.activeComponent = action.component || null;
      this.showComponent = true;
    },
    recalculateCost() {
      const action = this.actions.find((i) => i.action === "RecalculateCost");
      this.activeComponent = action.component || null;
      this.showComponent = true;
    },
    recalculateWeight(field, title) {
      const action = this.actions.find((i) => i.action === "RecalculateWeight");
      this.activeComponent = action.component || null;
      this.params = {
        field,
        title,
        t: Date.now(),
      };
      this.showComponent = true;
    },
  },
};
</script>

<style scoped>
table,
tbody,
tr,
th,
td {
  background-color: #ededed !important;
}

th {
  border-bottom: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}

td {
  border-bottom: transparent !important;
  padding: 0 10px 10px 0 !important;
}

table tr:last-child td {
  padding: 0 10px 4px 0 !important;
}

.v-data-table__wrapper {
  padding-bottom: 0 !important;
}

.position-relative {
  position: relative;
}

.document-add {
  position: absolute;
  bottom: 7%;
  right: 24%;
}

.root-width {
  width: 1440px;
}

.pinned {
  position: fixed;
  top: 0;
  right: 50%;
  width: 100vw;
  transform: translateX(50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 5;
}

#statistics-docs .v-data-table__wrapper table {
  width: 100% !important;
}
</style>
