<template>
  <v-row
    class="mx-0 mt-0"
  >
    <v-col
      cols="6"
      class="px-0 py-1"
      align-self="center"
    >
      <span class="font-weight-bold">
        Контрагент
        <slot name="menu" />
      </span>
    </v-col>
    <v-col
      cols="6"
      class="d-flex justify-end pb-0 pr-0 pt-0"
    >
      <slot name="search" />
      <v-btn
        tabindex="-1"
        min-width="32px"
        class="ml-3"
        text
        @click="$emit('open')"
      >
        <v-icon> mdi-chevron-up</v-icon>
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      class="px-0 pt-6"
    >
      {{ counterAgent.counter_agent_inn }}
      <span class="mx-5">
        {{ counterAgent.name }}
      </span>
      <span>
        {{ countryName }}
      </span>
    </v-col>
  </v-row>
</template>
<script>
import {getCountryNameByCode} from "@/helpers/catalogs";
import {mapGetters} from "vuex";

export default {
  props:{
    counterAgent:{
      required:true,
      type:Object
    },
    counterAgentJson:{
      required: true,
      type:Object
    }
  },
  computed:{
    ...mapGetters({
      countries:'catalogs/getNsiCountries'
    }),
    countryName(){
      return getCountryNameByCode(
        this.countries,
        this.counterAgentJson.country_letter
      )
    }
  }
}
</script>