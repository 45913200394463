<template>
  <v-autocomplete
    v-model="search"
    :loading="loading"
    :readonly="loading"
    :items="counterAgents"
    :filter="filterBySearchField"
    item-text="own_name"
    placeholder="Поиск в справочнике"
    item-value="id"
    auto-select-first
    background-color="white"
    return-object
    append-icon="mdi-magnify"
    dense
    outlined
    hide-details="true"
    @update:search-input="fetchCounterAgents($event)"
  />
</template>
<script>
import {filterBySearchFieldMixin} from "@/mixins/catalogs";

export default {
  mixins:[filterBySearchFieldMixin],
  data(){
    return {
      search:null,
      loading:false,
      counterAgents:[]
    }
  },
  watch:{
    search(counterAgent) {
      this.$emit('select',counterAgent)
      this.search = null
      this.counterAgents = []
    },
  },
  methods:{
    withSearchField(counterAgents){
      return counterAgents.map((counterAgent) => {
        return {
          ...counterAgent,
          search: `${counterAgent.name}-${counterAgent.own_name}-${counterAgent.unp}-${counterAgent.city}-${counterAgent.street}`,
        };
      });
    },
    fetchCounterAgents(value) {
      if (value && value.length > 3) {
        this.loading = true;
        this.$store.dispatch("division/searchNoResident", value)
          .then((res) => {
            this.counterAgents = this.withSearchField(res.data)
          })
          .finally(() => this.loading = false)
      }
    },
  }
}

</script>