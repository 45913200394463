<template>
  <copy-field-modal
    :headers="$options.DEFAULT_TABLE_HEADERS"
    :wares="preparedWares"
    :wares-params="waresParams"
    :show.sync="show"
  />
</template>
<script>
import CopyFieldModal from "@/components/documents/copy-field-modal.vue";
import {mapGetters} from "vuex";
import {DEFAULT_TABLE_HEADERS, prepareWares, prepareWaresParams} from "@/helpers/ware-copy-field";
import WareCopyFieldMixin from "@/mixins/ware-copy-field.mixin";
export default {
  DEFAULT_TABLE_HEADERS,
  components:{
    CopyFieldModal
  },
  mixins:[WareCopyFieldMixin],
  computed:{
    ...mapGetters({
      wares:"statistics/getSelectedWares"
    })
  },
  methods:{
    onModalOpen(waresParams){
      this.waresParams = prepareWaresParams(waresParams)
      this.preparedWares = prepareWares(this.wares)
      this.show = true
    },
  }
}
</script>
