<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-row>
      <v-col cols="6">
        <div
          class="statistics-box d-flex"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <v-col cols="6">
            <label>
              1 Декларация
              <v-menu offset-x>
                <template #activator="{ on }">
                  <v-icon
                    tabindex="-1"
                    dense
                    class="mx-1"
                    small
                    v-on="on"
                  >mdi-tune</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    :disabled="!isCorrectionRegnumActive"
                    @click="openReasonsCatalog"
                  >Поиск в справочнике
                  </v-list-item>

                </v-list></v-menu>
            </label>
            <div class="d-flex">
              <label>
                <v-text-field
                  ref="document_type"
                  v-model="type_declaration.document_type"
                  disabled
                  outlined
                  hide-details="auto"
                  dense
                  background-color="grey lighten-2"
                  class="mr-1"
                />
              </label>
              <label>
                <v-autocomplete
                  ref="customs_procedure"
                  v-model="type_declaration.customs_procedure"
                  auto-select-first
                  :items="customs_procedures"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  class="mr-1 p-0"
                />
              </label>
              <label>
                <v-autocomplete
                  ref="correction_kind"
                  v-model="type_declaration_json.correction_kind"
                  auto-select-first
                  :items="correction_kinds"
                  item-text="name"
                  item-value="value"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  class="mr-1"
                />
              </label>
              <div>
                <v-autocomplete
                  v-show="type_declaration_json.correction_kind === 'КС'"
                  ref="correction_reason_code"
                  v-model="type_declaration_json.correction_reason_code"
                  auto-select-first
                  :items="correction_types"
                  item-text="search"
                  item-value="code"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  class="mr-1"
                />
                <v-autocomplete
                  v-show="type_declaration_json.correction_kind === 'АН'"
                  ref="correction_reason_code__custom"
                  v-model="type_declaration_json.correction_reason_code"
                  auto-select-first
                  :items="cancellation_types"
                  item-text="search"
                  item-value="code"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  class="mr-1"
                />
                <v-text-field
                  v-show="
                    !['КС', 'АН'].includes(
                      type_declaration_json.correction_kind
                    )"
                  ref="correction_reason_code__default"
                  disabled
                  outlined
                  hide-details="auto"
                  dense
                  class="mr-1"
                />
              </div>
            </div>
          </v-col>
          <v-col
            cols="4"
            class="px-0"
          >
            <label>
              5 Предшествующий документ
              <field-menu>
                <v-list-item @click="clearRegNum">
                  Очистить графу
                </v-list-item>
              </field-menu>
            </label>
            <v-text-field
              ref="correction_reg_num"
              v-model="type_declaration_json.correction_reg_num"
              v-mask="'#####/######/C######'"
              :disabled="!isCorrectionRegnumActive"
              outlined
              hide-details="auto"
              dense
              :background-color="isCorrectionRegnumActive ? 'white' : ''"
              class="mr-1 p-0"
            />
          </v-col>
          <v-col
            cols="2"
          >
            <label>
              3 Формы
              <div class="d-flex">
                <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  disabled
                  :value="additionalPages"
                />
              </div>
            </label>
          </v-col>
        </div>
      </v-col>

      <v-col
        cols="6"
        class="pl-2"
      >
        <div class="statistics-box d-flex justify-space-between pt-3 pb-4 px-3">
          <div style="width:38%">
            <label>
              А Регистр. номер
              <v-menu offset-x>
                <template #activator="{ on }">
                  <v-icon
                    tabindex="-1"
                    dense
                    class="mx-1"
                    small
                    v-on="on"
                  >mdi-tune</v-icon>
                </template>
                <v-list>
                  <v-list-item

                    @click="disabled = !disabled"
                  >Ручное заполнение</v-list-item>
                  <v-list-item

                    @click="fillWithCustomsData"
                  >Заполнить данными из таможни</v-list-item>
                  <v-list-item

                    disabled
                  >Очистить регистрационный номер и сменить ID документа</v-list-item>
                </v-list>
              </v-menu>
            </label>
            <v-text-field
              ref="reg_num_a"
              v-model="registration_details.reg_num_a"
              v-mask="'#####/######/C######'"
              :disabled="disabled"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              outlined
              dense
              hide-details="auto"
            />
          </div>
          <div
            style="width:33%"
            class="px-2"
          >
            <label>B</label>
            <v-text-field
              ref="reg_num_b"
              v-model="registration_details.reg_num_b"
              v-mask="'#####/#C######/#'"
              :disabled="disabled"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              outlined
              dense
              hide-details="auto"
            />
          </div>
          <div>
            <label>Дата</label>
            <v-text-field
              ref="reg_date_b"
              v-model="registration_details.reg_date_b"
              :background-color="disabled ? 'grey lighten-2' : 'white'"
              :disabled="disabled"
              type="date"
              outlined
              dense
              hide-details="auto"
              append-icon="mdi-calendar"
              @change="registration_details.reg_date_b = deleteNulls($event)"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteNulls } from "@/helpers/inputs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import blockVisibility from '@/mixins/block-visibility'
import {
  type_declaration as onTypeDeclaration,
  registration_details as onRegistrationDetails
} from '@/events/statistics/control'
import { customsProcedureChanged } from "@/events/statistics";
import {highlightField} from "@/helpers/control"
import { showCatalog, catalogValueSelected } from "@/events/statistics";
import FieldMenu from "@/components/documents/field-menu.vue";
export default {
  components: {FieldMenu},
  mixins: [blockAutoUpdate, blockVisibility],
  data: () => ({
    block_id: "sd-main-info",
    menu: false,
    items: [],
    customs_procedures: ["ИМ", "ЭК"],
    date: null,
    disabled: true,
    correction_kinds: [
      {
        name: "",
        value: "",
      },
      {
        name: "КС",
        value: "КС",
      },
      {
        name: "АН",
        value: "АН",
      },
    ],

    type_declaration: {
      declaration_id: null,
      customs_procedure: "",
      document_type: "",
    },
    type_declaration_json: {
      correction_kind: "",
      correction_reason_code: "",
      correction_reg_num: "",
      ware_quantity: "",
    },
    registration_details: {
      declaration_id: null,
      reg_num_a: "",
      reg_num_b: "",
      reg_date_b: null,
    },
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      selected_wares: "statistics/getSelectedWares",
      doc_types: "catalogs/getNsiTypesOfDocument",
      correction_types: "catalogs/getNsiCorrectionReasons",
      cancellation_types: "catalogs/getNsiCancellationReasons",
    }),
    quantity() {
      return this.selected_wares?.length || 0;
    },
    isCorrectionRegnumActive() {
      return ["КС", "АН"].includes(this.type_declaration_json.correction_kind);
    },
    additionalPages() {
      return this.quantity ? Math.ceil(this.quantity / 5) : 0;
    },
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
        this.disabled = true
      },
      deep: true,
    },
    "type_declaration_json.correction_kind"(nv, old) {
      if (old && nv !== old) {
        this.type_declaration_json.correction_reason_code = "";
        if (nv === "") {
          this.type_declaration_json.correction_reg_num = "";
        }
      }
    },
  },
  created(){
    onTypeDeclaration.subscribe(this.highlightField)
    onRegistrationDetails.subscribe(this.highlightField)
    catalogValueSelected.subscribe(this.updatesResolver, [
      'type_declaration_json.correction_reason_code',
    ])
  },
  beforeDestroy(){
    onTypeDeclaration.unsubscribe()
    onRegistrationDetails.unsubscribe()
    catalogValueSelected.unsubscribe()
  },
  methods: {
    deleteNulls,
    highlightField,
    updatesResolver({field, code}){
      if(code && field){
        const [parent, child] = field.split('.')
        this[parent][child] = code
        this.readyToUpdate()
      }
    },
    readyToUpdate(){
      this.fields_been_visited = true
      this.hasChanges = true
    },
    clearRegNum(){
      this.type_declaration_json.correction_reg_num = null
      this.readyToUpdate()
    },
    setFields() {
      const {
        registration_details: { reg_num_a, reg_num_b, reg_date_b } = {},
        type_declaration: {
          declaration_id,
          customs_procedure,
          document_type,
          type_declaration_json: {
            correction_kind,
            correction_reason_code,
            correction_reg_num,
            ware_quantity,
          } = {},
        } = {},
      } = this.selected;

      this.type_declaration.declaration_id = declaration_id;
      //LEFT BLOCK
      this.type_declaration.customs_procedure = customs_procedure;
      this.type_declaration.document_type = document_type || "СД";

      this.type_declaration_json.correction_kind = correction_kind;
      this.type_declaration_json.correction_reason_code =
        correction_reason_code;
      this.type_declaration_json.correction_reg_num = correction_reg_num;
      this.type_declaration_json.ware_quantity = ware_quantity;

      //RIGHT BLOCK
      this.registration_details.reg_num_a = reg_num_a || "";
      this.registration_details.reg_num_b = reg_num_b || "";
      this.registration_details.reg_date_b = reg_date_b || "";

      this.registration_details.declaration_id = declaration_id;

      this.setHasChangesFalse();
    },
    openReasonsCatalog(){
      if(this.type_declaration_json.correction_kind === 'КС'){
        showCatalog.trigger({
          type: "correction_reason",
          search: this.type_declaration_json.correction_reason_code,
          field:"type_declaration_json.correction_reason_code" });
      }else if(this.type_declaration_json.correction_kind === 'АН'){
        showCatalog.trigger({
          type: "cancellation_reason",
          search: this.type_declaration_json.correction_reason_code,
          field:"type_declaration_json.correction_reason_code" });
      }else{
        return false
      }
    },
    fillWithCustomsData(){
      const {ecd_request_id} = this.selected
      this.$store.dispatch('journal/getStatusList', ecd_request_id).then(res => {
        const data = res.data?.files || []
        const registered = data.find(i => i.ln_type === 3)
        const canceled = data.find(i => i.ln_type === 5)
        if(registered && canceled){ // Если оба статуса
          Promise.all([
            this.$store.dispatch('journal/getStatusMessage', registered),
            this.$store.dispatch('journal/getStatusMessage', canceled)
          ]).then(res => {
            const [registeredResponse, canceledResponse] = res
            this.registration_details.reg_num_a = registeredResponse?.data?.regNumberA || ""
            this.registration_details.reg_num_b = canceledResponse?.data?.regNumberB || ""
            this.fieldsBeenVisitedByUser()
            this.triggerOnChange()
          }).catch(e => Promise.reject(e))
        }else if(registered || canceled){ // Если один из статусов
          const payload = registered || canceled
          this.$store.dispatch('journal/getStatusMessage', payload).then(res => {
            if('regNumberA' in res.data){
              this.registration_details.reg_num_a = res?.data?.regNumberA || ""
            }
            if('regNumberB' in res.data){
              this.registration_details.reg_num_b = res?.data?.regNumberA || ""
            }
            this.fieldsBeenVisitedByUser()
            this.triggerOnChange()
          }).catch(e => Promise.reject(e))
        }else{
          return
        }
      }).catch(err => {
        this.$snackbar({text:err?.response?.data || 'Ошибка', color:"red", top:false, right: false});
      })
    },
    async uploadData() {

      // В блоке сделки от типа Декларации надо обновить поле 7.3
      customsProcedureChanged.trigger({value:this.type_declaration.customs_procedure})

      const type_declaration = this.convertEmptyStringsToNull({
        declaration_id: this.declaration_id,
        ...this.type_declaration,
        type_declaration_json: this.type_declaration_json,
      });

      const registration_details = this.convertEmptyStringsToNull({
        declaration_id: this.declaration_id,
        ...this.registration_details,
      });

      // В целях отображения названия блока возвращаем респонс из этого запроса
      const typeDeclarationResponse = await this.$store.dispatch("statistics/uploadBlockData", {
        name: "type_declaration",
        value: type_declaration,
      });

      await this.$store.dispatch("statistics/uploadBlockData", {
        name: "registration_details",
        value: registration_details,
      });

      // При смене значения в каждом товаре должно сбросится значение
      // ПС.хорошо бы сделать проверку на то изменилось ли значение поля correction_kind
      // не разобрался с асинхронностью =(
      // значение предыдущее после первого изменения меняется и равно новому. почему хз
      await this.$store.dispatch("statistics/updateGoodsCorrectionReasonCode");

      return Promise.resolve(typeDeclarationResponse);
    },
  },
};
</script>
