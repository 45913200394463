<template>
  <v-col
    cols="12"
  >
    <counter-agent-collapsed
      v-show="collapsed"
      :counter-agent="counter_agent"
      :counter-agent-json="counter_agent_json"
      @open="openDetailedView"
    >
      <template #menu>
        <counter-agent-menu @saveToCatalog="showArchiveModal" />
      </template>
      <template #search>
        <counter-agent-search @select="onCounterAgentSelect" />
      </template>
    </counter-agent-collapsed>
    <v-row
      v-show="!collapsed"
      class="statistics-box mt-0 pb-15"
    >
      <v-col
        cols="6"
        class="d-flex justify-space-between align-center pb-0 pt-0"
      >
        <span class="font-weight-bold">
          Контрагент
          <counter-agent-menu @saveToCatalog="showArchiveModal" />
        </span>
      </v-col>

      <v-col
        cols="6"
        class="d-flex justify-end pb-0 pt-0"
      >
        <counter-agent-search @select="onCounterAgentSelect" />
        <v-btn
          tabindex="-1"
          min-width="32px"
          class="ml-3"
          text
          @click="$emit('update:collapsed', true)"
        >
          <v-icon> mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>

      <v-col
        cols="5"
        class="pb-0 pt-0"
      />
      <v-col
        :id="block_id"
        v-click-outside="triggerOnFocus"
        cols="12"
        class="pt-6"
      >
        <v-row>
          <v-col
            cols="3"
            class="pb-0"
            align-self="end"
          >
            <label>
              ИНН/УНН/ИИН/Б-ИН
              <v-text-field
                key="inn-with-mask"
                ref="counter_agent_inn"
                v-model="counter_agent.counter_agent_inn"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="6"
            class="pb-0"
            align-self="end"
          >
            <div class="d-flex align-center">
              <label class="mb-0">Наименование организации</label>
              <v-switch
                v-model="isIndividual"
                tabindex="-1"
                label="ИП"
                class="order-0 mx-2 pb-0"
                color="#5CB7B1"
                dense
                hide-details="auto"
              />
            </div>
            <v-text-field
              ref="counter_agent_name"
              v-model="counter_agent.name"
              outlined
              dense
              hide-details="auto"
              background-color="white"
            />
          </v-col>

          <v-col
            cols="3"
            class="pb-0"
            align-self="end"
          >
            <label>
              Страна
              <v-menu offset-x>
                <template #activator="{ on, attrs }">
                  <v-icon
                    tabindex="-1"
                    class="mx-1"
                    small
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >mdi-tune
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-if="!customFieldChecker(countries, counter_agent_json.country_letter, 'letterCode')"
                    @click="counter_agent_json.country_letter = ''"
                  >
                    Значение из справочника
                  </v-list-item>
                  <v-list-item
                    v-else
                    @click="counter_agent_json.country_letter = null"
                  >
                    Произвольное значение
                  </v-list-item>
                </v-list>
              </v-menu>
            </label>
            <v-text-field
              v-show="!customFieldChecker(countries, counter_agent_json.country_letter, 'letterCode')"
              ref="country_letter__custom"
              v-model="counter_agent_json.country_letter"
              v-mask="'XX'"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              @input="(v) => counter_agent_json.country_letter = v === '' ? null : v"
            />
            <v-autocomplete
              v-show="customFieldChecker(countries, counter_agent_json.country_letter, 'letterCode')"
              ref="country_letter"
              v-model="counter_agent_json.country_letter"
              auto-select-first
              :items="countries"
              item-text="text"
              item-value="letterCode"
              outlined
              dense
              hide-details="auto"
              background-color="white"
              :filter="filterBySearchField"
              @input="(v) => counter_agent_json.country_letter = v === null ? '' : v"
            />
          </v-col>
          <v-col
            cols="2"
            class="pb-0"
          >
            <label>
              Индекс
              <v-text-field
                ref="postal_index"
                v-model="counter_agent_json.postal_index"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="5"
            class="pb-0"
          >
            <label>
              Область/Район
              <v-text-field
                ref="region"
                v-model="counter_agent_json.region"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col
            cols="5"
            class="pb-0"
          >
            <label>
              Город/Деревня
              <v-text-field
                ref="city"
                v-model="counter_agent_json.city"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>

          <v-col cols="9">
            <label>
              Адрес
              <v-text-field
                ref="street_house"
                v-model="counter_agent_json.street_house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
          <v-col cols="3">
            <label>
              Телефон
              <v-text-field
                ref="phone"
                v-model="counter_agent_json.phone"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </label>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <archive
      :show.sync="show"
      :show-contract="false"
      @save="saveToArchive"
    />
  </v-col>
</template>
<script>
import {mapGetters} from "vuex";
import {
  getCountryCodeByName,
  getCountryNameByCode,
  getContragentFromNoResident,
} from "@/helpers/catalogs";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import customFieldChecker from '@/mixins/custom-field-checker'
import Archive from "./archive.vue";
import {setContragent} from "@/events/statistics";
import {counter_agent as onCounterAgent} from '@/events/statistics/control'
import {highlightField} from '@/helpers/control'
import CAgentModel from '@/models/statistics/counter_agent/index'
import CAgentDictionary from '@/models/statistics/counter_agent/dictionary'
import {validate, errorsToArray, errorsToPathList} from '@/helpers/validation'
import CounterAgentCollapsed from './counter-agent-collapsed.vue'
import CounterAgentMenu from './counter-agent-menu.vue'
import CounterAgentSearch from "./counter-agent-search.vue";

export default {
  components: {Archive, CounterAgentCollapsed, CounterAgentMenu, CounterAgentSearch},
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, customFieldChecker],
  props: {
    collapsed: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    block_id: "statistics-contragent-block",
    isIndividual: false,
    show: false,
    counter_agent: {
      declaration_id: "",
      counter_agent_inn: "",
      name: "",
    },
    counter_agent_json: {
      country_letter: "",
      postal_index: "",
      region: "",
      city: "",
      street_house: "",
      phone: "",
    },
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      countries: "catalogs/getNsiCountries",
    }),
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true
    },
  },
  created() {
    setContragent.subscribe(this.onCounterAgentSelect)
    onCounterAgent.subscribe(this.highlightField)
  },
  beforeDestroy() {
    setContragent.unsubscribe()
    onCounterAgent.unsubscribe()
  },
  methods: {
    getCountryNameByCode,
    highlightField,
    showArchiveModal(){
      this.show = true
    },
    openDetailedView(){
      this.$emit('update:collapsed', false)
    },
    onCounterAgentSelect(counterAgent) {
      if (counterAgent && Object.keys(counterAgent).length) {
        this.updateFieldsOnSearch(counterAgent);
        this.uploadData().then(() => {
          this.$success("Обновлено [Контрагент]");
        }).catch(() => this.$error())
      }
    },
    updateFieldsOnSearch(item) {
      const {counter_agent, counter_agent_json} = getContragentFromNoResident(
        {
          item,
          declaration_id: this.selected.counter_agent.declaration_id,
        }
      );
      this.counter_agent = counter_agent;
      this.counter_agent_json = counter_agent_json;
    },
    setFields() {
      const {counter_agent} = this.selected
      const {counter_agent_json} = counter_agent
      Object.keys(this.counter_agent).forEach(key => {
        if (key in counter_agent) {
          this.counter_agent[key] = counter_agent[key]
        }
      })
      Object.keys(this.counter_agent_json).forEach(key => {
        if (key in counter_agent_json) {
          this.counter_agent_json[key] = counter_agent_json[key]
        }
      })
      this.setCounterAgentName(counter_agent)
      this.setCounterAgentCountry(counter_agent_json)
      this.setIsIndividual(counter_agent)
      this.setHasChangesFalse();
    },
    setCounterAgentName(counterAgent) {
      this.counter_agent.name = counterAgent.counter_agent_name || counterAgent.counter_agent_person_name;
    },
    setCounterAgentCountry(counterAgentJson) {
      this.counter_agent_json.country_letter =
          counterAgentJson.country_letter || getCountryCodeByName(this.countries, counterAgentJson.country_letter);
    },
    setIsIndividual(counterAgent) {
      this.isIndividual =
          counterAgent.counter_agent_name === null && counterAgent.counter_agent_person_name !== null;
    },
    getCompleteBody() {
      const body = {
        ...this.counter_agent,
        counter_agent_name: this.isIndividual ? null : this.counter_agent.name,
        counter_agent_person_name: this.isIndividual
          ? this.counter_agent.name
          : null,
        counter_agent_json: {
          ...this.counter_agent_json,
          country_name: getCountryNameByCode(
            this.countries,
            this.counter_agent_json.country_letter
          ),
        },
      };
      delete body.name;
      return body
    },
    uploadData() {
      const payload = this.getCompleteBody()
      const counter_agent = this.convertEmptyStringsToNull(payload);

      const errorsObject = validate({
        payload: counter_agent,
        model: CAgentModel,
        dictionary: CAgentDictionary
      })
      if (Object.keys(errorsObject).length) {
        const errors = errorsToArray(errorsObject)
        const list = errorsToPathList(errorsObject)
        list.forEach(path => {
          this.highlightField({path, scroll: false})
        })
        return Promise.reject({validation: errors, payload})
      }

      return this.$store.dispatch("statistics/uploadBlockData", {
        name: "counter_agent",
        value: counter_agent,
      });
    },
    saveCounterAgentToArchive({alias}) {
      this.$store.dispatch("division/saveNoResident", {alias})
        .then(() => this.$info("Справочник обновлен"))
        .catch(err => {
          const message = err.response.status === 400 ? err.response.data : "Ошибка"
          this.$error(message)
        });
    },
    saveToArchive(payload) {
      if (this.hasChanges && this.fields_been_visited) {
        this.uploadData().then(() => {
          this.saveCounterAgentToArchive(payload);
        });
      } else {
        this.saveCounterAgentToArchive(payload);
      }
    },
  },
};
</script>
<style scoped>
span {
  min-height: 24px;
}
</style>
