<template>
  <ware-card
    :id="block_id"
    :index="index"
    :sort-index="item.sort_index"
    selector-class="statistics-goods-item"
    @outside="triggerOnFocus"
    @toggle="onToggle"
    @toggle-tick="onToggleTick"
  >
    <template #title>
      <ware-title :item="item" />
    </template>
    <template #menu>
      <v-list>
        <v-list-item
          class="wares-item-menu-delete"
          @click="deleteItem"
        >
          Удалить
        </v-list-item>
        <v-list-item
          class="wares-item-menu-copy"
          @click="copyItem"
        >
          Копировать
        </v-list-item>
      </v-list>
    </template>
    <template #body>
      <transportation
        :index="index"
        :visited.sync="fields_been_visited"
        :control="waresControlPath"
        @update="updateInfoInComponent"
      />
      <description
        :index="index"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        :ware-details="wareDetails"
        :show-quantity="showWareDetailQuantity"
        :control="waresControlPath"
        :current-state="info"
        @update="updateInfoInComponent"
      />
      <parameters
        ref="params"
        :index="index"
        :visited.sync="fields_been_visited"
        :shipment-date="info.shipment_date"
        :tnved="info.tn_ved"
        :show-quantity="showWareDetailQuantity"
        :control="waresControlPath"
        @update="updateInfoInComponent"
        @recalculated="checkAndSetVisited"
        @measure-changed="onAddMeasureChanged"
      />
    </template>
  </ware-card>
</template>

<script>
import Transportation from "@/components/statistics/goods/transportation.vue";
import Description from "@/components/statistics/goods/description.vue";
import Parameters from "@/components/statistics/goods/parameters.vue";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import {wares as onWares} from '@/events/statistics/control'

import {
  forceUpdateWareDetail,
  wareDetailModified,
  addPromise
} from "@/events/statistics";
import {getFirstValueFromMap, removeFirstFromMap} from "@/helpers/control";
import WareCard from "@/components/documents/ware/ware-card.vue";
import WareTitle from "@/components/documents/ware/ware-title.vue";
import {removeAddMeasureQuantity} from "@/helpers/ware-details";

export default {
  components: {
    WareTitle,
    WareCard,
    Transportation,
    Description,
    Parameters,
  },
  mixins: [blockAutoUpdate],
  props: {
    item: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    show: false,
    block_id: null,
    is_goods_item: true,
    info: {},
    wareDetails: [],
    promises: [],
    promisesWares: [],
    onWares: null,
    waresControlPath: {
      path:null
    },
    addMeasureLetterInput: null
  }),
  computed: {
    showWareDetailQuantity() {
      return !!(this.info.add_measure_unit_letter || document.activeElement === this.addMeasureLetterInput) || false
    },
  },
  watch: {
    item: {
      handler(info) {
        this.info = info;
        this.setBlockId()
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    wareDetailModified.subscribe(this.checkAndSetVisited);
    addPromise.subscribe(this.onComponentPromise)
    this.onWares = {...onWares} // Делаем локальную копию чтобы можно было менять имя события
    this.onWares.name = `${this.onWares.name}-${this.index + 1}`
    this.onWares.subscribe(this.onControl)
  },
  beforeDestroy() {
    this.onWares.unsubscribe()
    wareDetailModified.unsubscribe();
    addPromise.unsubscribe();
  },
  methods: {
    setBlockId(){
      this.block_id = `statistics-goods-item-${this.index}`;
    },
    markAsEditable(){
      if(!this.info.id) return
      this.$store.commit("statistics/SET_EDITABLE_WARE", {
        id: this.info.id,
        index: this.index,
      });
    },
    onToggle(value){
      value ? this.markAsEditable() : this.destroyEventListeners()
    },
    onToggleTick(value){
      if(value){
        this.fetchWareDetails()
        this.setEventListeners()
        this.setAddMeasureField()
      }else{
        this.unsetAddMeasureField()
      }
    },
    onControl({path}) {
      const index = getFirstValueFromMap(path)
      const selector = ".statistics-goods-item:nth-of-type(" + index + ")"
      const el = document.querySelector(selector)

      this.$scrollTo(el, 200, {
        force: true,
        onDone: () => {
          removeFirstFromMap(path)
          this.highlight({el, path})
        }
      })
    },
    highlight({el, path}) {
      const [wrapper] = el.children
      if (wrapper.classList.contains('transportation-box__header_close')) {
        const [toggle] = wrapper.children
        const clickEvent = this.getClickEvent()
        toggle.dispatchEvent(clickEvent)
        this.waresControlPath = {path}
      } else {
        this.waresControlPath = {path}
      }
    },
    getClickEvent(){
      return new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      });
    },
    // Cохраяем полученные промисы - раздельно товары и блоки
    onComponentPromise({id, promise, type = "block"}) {
      if (id === this.info.id) {
        type === "ware" ? this.promisesWares.push(promise) : this.promises.push(promise)
      }
    },
    onAddMeasureChanged({value}) {
      if ((!value || value === "") && this.wareDetails.length) {
        const updated = removeAddMeasureQuantity(this.wareDetails)
        if (!updated.length) return;
        console.log('HERE')
        this.$store.dispatch('statistics/updateWareDetail', updated).then(() => {
          this.fetchWareDetails()
        })
      }
    },
    // Ставит "посещен" и "были изменения" в true
    checkAndSetVisited({index, action}) {
      if (this.index === index) {
        this.updateWareDetails(action);
        // Разрешаем блоку обновиться
        this.fieldsBeenVisitedByUser();
        this.triggerOnChange();
        if (["add", "delete"].includes(action)) {
          this.updateEventListeners();
        }
      }
    },
    updateWareDetails(action) {
      if (["add", "delete"].includes(action)) {
        this.fetchWareDetails();
      }
    },
    unsetAddMeasureField(){
      this.addMeasureLetterInput = null
    },
    setAddMeasureField() {
      const params = this.$refs.params.$el
      this.addMeasureLetterInput = params?.querySelector('.add_measure_unit_digit input') || null
    },
    fetchWareDetails() {
      this.$store
        .dispatch("statistics/fetchWareDetails", {wareId: this.item.id})
        .then((res) => {
          this.wareDetails = [...res.data];
        });
    },
    updateInfoInComponent(data) {
      this.info = {
        ...this.info,
        ...data,
      };
    },
    uploadData() {
      return Promise.all(this.promises).then(() => {
        this.promises = []
        forceUpdateWareDetail.trigger({id: this.item.id});
        return Promise.all(this.promisesWares)
      }).then(() => {
        this.promisesWares = []
        return this.$store.dispatch("statistics/updateGoodsItem", this.info);
      })
    },
    deleteItem() {
      const {id, sort_index} = this.item;
      const index = this.index;
      this.$store.dispatch("statistics/deleteGoodsItem", {
        id,
        sort_index,
        index,
      }).then(() => this.$info("Товар удален"))
        .catch(() => this.$error("Ошибка удаления товара"));
    },
    copyItem() {
      const {id} = this.item
      this.$store.dispatch('statistics/copyWare', {id}).then(() => {
        this.$snackbar({text: 'Товар скопирован', top: false, right: false})
      }).catch((err) => this.$error(`Не удалось скопировать товар. ${err.response.data}`)
      )
    }
  },
};
</script>
