<template>
  <v-col
    cols="12"
    class="statistics-box my-3"
    :class="{'blocked-box' : disabledView}"
  >
    <v-col
      cols="12"
      class="pb-0 px-0"
    >
      <span class="font-weight-bold">Сведения о товаре
      </span>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col
          cols="2"
          class="mx-n3"
        >
          <label>
            16 Код товара
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  tabindex="-1"
                  class="mx-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="showTNVED">
                  Поиск в справочнике
                </v-list-item>
                <v-list-item
                  @click="copyToAll('tn_ved', item.tn_ved )"
                >
                  Применить ко всем товарам
                </v-list-item>
                <v-list-item @click="copy('tn_ved', item.tn_ved, 'apply')">
                  Применить к выбранным товарам
                </v-list-item>
              </v-list>
            </v-menu>
            <div class="d-flex align-center">
              <v-text-field
                ref="tn_ved"
                v-model="item.tn_ved"
                v-mask="'##########'"
                outlined
                dense
                background-color="white"
                hide-details="auto"
                class="tn_ved"
                @keypress.enter="showTNVED"
                @change="tnvedChanged"
              />
              <v-btn
                :class="{'teal lighten-2': tab === 0}"
                max-width="36px"
                min-width="36px"
                class="ml-2"
                text
                @click="toggleTreeView"
              >
                <v-icon>
                  mdi-file-tree-outline
                </v-icon>
              </v-btn>
              <v-btn
                max-width="36px"
                min-width="36px"
                text
                @click="showCodeInfo(item.tn_ved)"
              >
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </div>
          </label>
        </v-col>
        <v-col
          cols="2"
          class=""
        >
          <label>
            <v-menu offset-x>
              <template #activator="{ on, attrs }">
                <v-icon
                  tabindex="-1"
                  class="mx-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="showExtraTNVED">
                  Поиск в справочнике
                </v-list-item>
                <v-list-item
                  @click="copyToAll('add_tn_ved', item.add_tn_ved )"
                >
                  Применить ко всем товарам
                </v-list-item>
                <v-list-item @click="copy('add_tn_ved', item.add_tn_ved, 'apply', index)">
                  Применить к выбранным товарам
                </v-list-item>
                <v-list-item @click="openRulesModal">Заполнить по правилу</v-list-item>
              </v-list>
            </v-menu>

          </label>
          <div class="d-flex align-center mr-16 ">
            <v-text-field
              ref="add_tn_ved"
              v-model="item.add_tn_ved"
              v-mask="'####'"
              outlined
              dense
              background-color="white"
              hide-details="auto"
              class="add_tn_ved"
              @keypress.enter="showExtraTNVED"
            />
            <tnved-tooltip :item="item" />
          </div>
        </v-col>
        <v-col
          cols="2"
          class="mx-n3"
        >
          <label>
            Признак коррект.
            <v-menu
              v-if="selected.type_declaration.type_declaration_json.correction_kind === 'КС'"
              offset-x
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  tabindex="-1"
                  class="mx-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune
                </v-icon>
              </template>
              <v-list>
                <v-list-item @click="openCorrectionReason">
                  Поиск в справочнике
                </v-list-item>
                <v-list-item
                  @click="copyToAll('correction_reason_code', item.correction_reason_code)"
                >
                  Применить ко всем товарам
                </v-list-item>
                <v-list-item
                  @click="copy('correction_reason_code', item.correction_reason_code, 'apply', index)"
                >
                  Применить к выбранным товарам
                </v-list-item>
              </v-list>
            </v-menu>
            <div
              cols="3"
              class="d-flex align-center"
            >
              <label
                v-show="
                  isCorrection &&
                    selected.type_declaration.type_declaration_json
                      .correction_kind === 'КС'
                "
              >
                <v-autocomplete
                  ref="correction_reason_code"
                  v-model="item.correction_reason_code"
                  auto-select-first
                  :items="correction_types"
                  item-text="search"
                  item-value="code"
                  outlined
                  hide-details="auto"
                  dense
                  background-color="white"
                  class="mr-4 correction_kind_KC"
                />
              </label>
              <label
                v-show="
                  isCorrection &&
                    selected.type_declaration.type_declaration_json
                      .correction_kind === 'АН'
                "
              >
                <v-autocomplete
                  ref="correction_reason_code__custom"
                  v-model="item.correction_reason_code"
                  auto-select-first
                  :items="cancellation_types"
                  disabled
                  item-text="code"
                  item-value="code"
                  outlined
                  hide-details="auto"
                  dense
                  class="mr-4 correction_kind_AH"
                />
              </label>
              <label v-show="!isCorrection">
                <v-text-field
                  ref="correction_reason_code__default"
                  disabled
                  outlined
                  hide-details="auto"
                  dense
                  class="mr-4"
                />
              </label>
            </div>
          </label>
        </v-col>
        <v-col
          class="pa-0"
          cols="12"
        >
          <v-row>
            <ware-dynamic-tnved-tabs
              v-model="tab"
              class="pb-0"
              :tabs="tabs"
              :code="code"
            />
            <goods-description
              ref="description"
              v-model="item.description"
              :index="index"
              :item="item"
              :ware-details="wareDetails"
              :show-quantity="showQuantity"
            />
            <v-col cols="12">
              <div class="d-flex align-center justify-space-between">
                <label>
                  Количество акцизных марок
                  <v-text-field
                    ref="excise_quantity"
                    v-model="item.excise_quantity"
                    :disabled="getExciseVisibility"
                    :background-color="getExciseVisibility ? 'grey lighten-2' : 'white'"
                    outlined
                    dense
                    hide-details="auto"
                    class="excise_quantity"
                  />
                </label>
                <div class="d-flex align-center">
                  <label>
                    Отгрузка/Поступление
                    <v-menu offset-x>
                      <template #activator="{ on, attrs }">
                        <v-icon
                          tabindex="-1"
                          class="mx-1"
                          small
                          dense
                          v-bind="attrs"
                          v-on="on"
                        >mdi-tune
                        </v-icon>
                      </template>
                      <v-list>
                        <v-list-item
                          @click="copyToAll('shipment_date', item.shipment_date)"
                        >
                          Применить ко всем товарам
                        </v-list-item>
                        <v-list-item @click="copy('shipment_date', item.shipment_date, 'apply', index)">
                          Применить к выбранным товарам
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <custom-date-picker
                      ref="shipment_date"
                      v-model="item.shipment_date"
                      :visited.sync="visitedLocal"
                      :has-changes.sync="hasChangesLocal"
                      input-class="shipment_date"
                      @change="shipmentChanged($event)"
                    />
                  </label>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
import {mapGetters} from "vuex";
import {deleteNulls} from "@/helpers/inputs";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import GoodsDescription from "./description/index.vue";
import CustomDatePicker from "@/components/shared/custom-date-picker"
import TnvedTooltip from "./tnved-tooltip.vue"

import {
  showCatalog,
  catalogValueSelected,
  tnvedCodeSelected,
  tnvedCodeChanged,
  wareShipmentDateChanged,
  addPromise, addExtraTnvedRules
} from "@/events/statistics";
import highlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import moment from "moment";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import WareDynamicTnvedTabs from "@/components/documents/ware/ware-dynamic-tnved-tabs.vue";

export default {
  name: "Description",
  components: {WareDynamicTnvedTabs, GoodsDescription, CustomDatePicker, TnvedTooltip},
  mixins: [highlightFieldWareSector, copyFieldMixin],
  props: {
    index: {
      required: true,
      type: Number,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
    wareDetails: {
      required: true,
      type: Array,
    },
    showQuantity: {
      required: true,
      type: Boolean,
    },
    currentState: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    tab: null,
    selectedTab: null,
    tabs: [
      {
        title: "Показать структуру",
        id: 1,
        component: () => import("@/components/tnved/structure.vue"),
      },
    ],
    current_goods_item: {}, // весь товар
    item: {
      id: "",
      declaration_id: "",
      tn_ved: "",
      add_tn_ved: "",
      correction_reason_code: "",
      description: "",
      excise_quantity: "",
      shipment_date: "",
    },
    customs_procedure: '',
    code: "", // Свойство для Структуры ТНВЭД. Вынесено отдельно, чтобы обновляться только по @change
  }),
  computed: {
    ...mapGetters({
      codePage: "tnved/getCodePage",
      selected: "statistics/getSelected",
      selected_wares: "statistics/getSelectedWares",
      correction_types: "catalogs/getNsiCorrectionReasons",
      cancellation_types: "catalogs/getNsiCancellationReasons",
      nsiTarifs: "catalogs/getNsiTarifs", // 2202910000 - пример для проверки
      disabledView: "statistics/getVisibility"
    }),
    visitedLocal: {
      get() {
        return this.visited
      },
      set(value) {
        this.$emit('update:visited', value)
      }
    },
    hasChangesLocal: {
      get() {
        return this.hasChanges
      },
      set(value) {
        this.$emit('update:has-changes', value)
      }
    },
    getExciseVisibility() {
      return this.customs_procedure === 'ЭК' ? true : !this.nsiTarifs.find(i => i === this.item.tn_ved)
    },
    isCorrection() {
      const value =
          this.selected?.type_declaration?.type_declaration_json
            ?.correction_kind ?? false;
      return !!(value && ["КС", "АН"].includes(value));
    },
  },
  watch: {
    "item.excise_quantity": {
      handler(nv) {
        const result = nv === null || nv === '' ? '' : Number.parseInt(nv)
        this.$nextTick(() => this.item.excise_quantity = result)
      }
    },
    selected_wares: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    tab: {
      handler(newValue) {
        this.selectedTab = newValue;
      },
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeSelected.subscribe(this.checkAndUpdateTNVED);
    catalogValueSelected.subscribe(this.updatesResolver, [
      'ware/add_tn_ved',
      'ware/correction_reason_code'
    ])
  },
  beforeDestroy() {
    tnvedCodeSelected.unsubscribe();
    catalogValueSelected.unsubscribe()
  },
  methods: {
    toggleTreeView(){
      this.tab = this.tab === 0 ? null : 0
    },
    openRulesModal(){
      addExtraTnvedRules.trigger()
    },
    updatesResolver({id, field, code}) {
      if (id === this.item.id) {
        if (code && field) {
          const [, key] = field.split('/')
          this.item[key] = code
          this.$emit('update:visited', true)
          this.$emit('update:has-changes', true)
        }
      }
    },
    deleteNulls,
    setFields() {
      // const { wares } = this.selected;
      this.current_goods_item = this.selected_wares[this.index];
      for (let key in this.current_goods_item) {
        if (key in this.item) {
          this.item[key] = this.current_goods_item[key];
        }
      }
      // Начальное значение кода ТНВЭД в структуре
      this.code = this.current_goods_item.tn_ved;
      this.customs_procedure = this.selected.type_declaration.customs_procedure
    },
    updateEditableGoodsItem() {
      const description = convertEmptyStringsToNull(this.item);
      this.$emit("update", description);
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
    showCodeInfo() {
      if (this.item.tn_ved.length) {
        window.open(
          `http://${location.host}/tnved/${this.item.tn_ved}`,
          "_blank"
        );
      }
    },
    openCorrectionReason() {
      let type;
      if (this.selected.type_declaration.type_declaration_json.correction_kind === 'КС') {
        type = "correction_reason"
      } else {
        return false
      }
      showCatalog.trigger({
        id: this.item.id,
        type,
        search: this.item.correction_reason_code,
        field: "ware/correction_reason_code"
      });
    },
    getActionDate() {
      const format = 'DD.MM.YYYY'
      const date = this.currentState.shipment_date
      return moment(date, 'YYYY-MM-DD').isValid() ? moment(date).format(format) : moment().format(format)
    },
    showExtraTNVED() {
      showCatalog.trigger({
        type: "tnved_extra",
        id: this.item.id,
        search: this.item.tn_ved,
        field: 'ware/add_tn_ved',
        actionDate: this.getActionDate()
      });
    },
    showTNVED() {
      showCatalog.trigger({
        type: "tnved",
        id: this.item.id,
        search: this.item.tn_ved,
      });
    },
    // Событие при выборе кода из справочника
    checkAndUpdateTNVED({id, code}) {
      if (id === this.item.id) {
        this.item.tn_ved = code;
        this.code = code;
        this.checkExtraTnved()
        this.updateExciseQuantity() // Если не предусмотрены акцизы - чистим
        // Обновляем доп.единицу измерения в параметрах
        tnvedCodeChanged.trigger({id: this.item.id, code: this.code});
        this.$emit('update:visited', true)
        this.$emit('update:has-changes', true)
      }
    },
    checkExtraTnved() {
      if ([null, ""].includes(this.item.tn_ved)) {
        this.item.add_tn_ved = ""
      } else {
        const promise = new Promise((resolve, reject) => {
          const actionDate = this.getActionDate()
          this.$store.dispatch('catalogs/getExtraTnved', {code: this.item.tn_ved, actionDate}).then(res => {
            if (res.data?.length === 1) {
              const [element] = res.data
              const {code} = element
              this.item.add_tn_ved = code === "0000" ? "0000" : ""
            } else {
              this.item.add_tn_ved = ""
            }
            resolve(res)
          }).catch((err) => reject(err))
        })
        addPromise.trigger({id: this.selected_wares[this.index].id, promise})
        return promise
      }
    },
    // Событие при "выходе" из инпута
    tnvedChanged() {
      this.code = this.item.tn_ved;
      this.checkExtraTnved()
      this.updateExciseQuantity() // Если не предусмотрены акцизы - чистим
      // Обновляем доп.единицу измерения в параметрах
      tnvedCodeChanged.trigger({id: this.item.id, code: this.code});
    },
    updateExciseQuantity() {
      if (!this.nsiTarifs.includes(this.item.tn_ved)) {
        this.item.excise_quantity = ""
      }
    },
    shipmentChanged(value) {
      this.item.shipment_date = this.deleteNulls(value);
      wareShipmentDateChanged.trigger({
        id: this.item.id,
        date: this.item.shipment_date,
      });
    },
  },
};
</script>
<style scoped>
label {
  margin: 0;
}

.tnved-box {
  height: 200px;
  overflow-y: scroll;
}

.show-tnved {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
</style>
