<template>
  <div id="statistics-goods-block">
    <v-container
      fluid
    >
      <v-dialog
        v-model="dialogAccept"
        width="550"
      >
        <v-card>
          <v-toolbar
            color="#333"
            dark
          >
            <div class="text-h5">
              Подтвердить действие
            </div>
          </v-toolbar>
          <div class="pa-6">
            <div class="text-body-1 black--text">
              Удалить все товары?
            </div>
          </div>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="#333"
              text
              @click="dialogAccept = false"
            >
              Отмена
            </v-btn>
            <v-btn
              color="#5cb7b1"
              dark
              @click="deleteAllGoods"
            >
              Подтвердить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--  -->
      <v-row class="mb-1">
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <span class="font-weight-bold">4 Всего товаров ({{ wares.length }})</span>
          <v-menu
            v-if="!blocked_visibility"
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-btn
                max-height="32px"
                min-width="32px"
                max-width="32px"
                text
                tabindex="-1"
                v-bind="attrs"
                class="ml-2 elevation-0 wares-menu"
                v-on="on"
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="wares-menu-sort-id"
                @click="sortGoods('id')"
              >
                Сортировать по порядку
              </v-list-item>
              <v-list-item
                class="wares-menu-sort-tnved"
                @click="sortGoods('code')"
              >
                Сортировать по ТНВЭД
              </v-list-item>
              <v-list-item
                class="wares-menu-sort-tnved"
                @click="openCustomSortingModal"
              >
                Произвольная сортировка
              </v-list-item>
              <v-list-item @click="dialogAccept = true">
                Удалить все товары
              </v-list-item>
              <v-list-item
                @click="deleteSeveralGoods"
              >
                Удалить несколько товаров
              </v-list-item>
            </v-list>
          </v-menu>
          <goods-modal
            :show.sync="waresModal"
            :wares-params="waresParams"
          />
        </v-col>
        <v-col

          v-if="!blocked_visibility"
          cols="2"
        >
          <upload-excel />
        </v-col>
        <v-col cols="2">
          <download-excel />
        </v-col>
        <v-col
          cols="3"
        >
          <download-specification />
        </v-col>
      </v-row>
      <div class="statistics-goods-list">
        <goods-item
          v-for="(item, idx) in wares"
          :key="'goods-items-' + idx"
          ref="goodsItem"
          :item="item"
          :index="idx"
          :blockedvis="blocked_visibility"
        />
      </div>

      <v-col
        v-if="!blocked_visibility"
        cols="12"
        class="px-0 d-flex justify-space-between align-end"
      >
        <v-btn
          color="#5CB7B1"
          :disabled="inProcess"
          @click="addGoodsItem"
        >
          <v-icon>mdi-plus</v-icon> Добавить товар
        </v-btn>
      </v-col>
      <component
        :is="activeComponent"
        :show.sync="showComponent"
      />
    </v-container>
  </div>
</template>
<script>
import GoodsItem from "./goods/goods-item.vue";
import { mapGetters } from "vuex";
import GoodsModal from './goods/goods-modal.vue'
import UploadExcel from '@/components/statistics/goods/upload-excel.vue'
import DownloadExcel from '../documents/download-excel.vue'
import blockVisibility from '@/mixins/block-visibility'
import {openGoodsModal} from '@/events/common'
import DownloadSpecification from "@/components/documents/download-specification.vue";
import {addExtraTnvedRules} from "@/events/statistics";
export default {
  components: {
    DownloadSpecification,
    GoodsItem,
    GoodsModal,
    UploadExcel,
    DownloadExcel
  },
  mixins: [blockVisibility],
  data:() => ({
    inProcess:false,
    waresModal: false,
    waresParams: null,
    dialogAccept: false,
    showComponent:false,
    activeComponent:null,
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      wares: "statistics/getSelectedWares"
    }),
  },
  created() {
    openGoodsModal.subscribe(this.onOpenModal)
    addExtraTnvedRules.subscribe(this.onFillExtraTnved)
  },
  beforeDestroy(){
    openGoodsModal.unsubscribe()
    addExtraTnvedRules.unsubscribe()
  },
  methods: {
    onFillExtraTnved(){
      this.activeComponent = () => import('@/components/statistics/goods/tnved/add-extra-tnved-rules.vue')
      this.showComponent = true
    },
    openCustomSortingModal(){
      this.activeComponent = () => import('@/components/statistics/goods/custom-sorting-modal.vue')
      this.showComponent = true
    },
    deleteAllGoods(){
      this.$store.dispatch("statistics/deleteAllGoods")
        .then(() => {
          this.$snackbar({text:"Действие применено", top:false, right: false})
        })
        .catch(() => {
          this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
        })
      this.dialogAccept = false
    },
    deleteSeveralGoods(){
      this.waresParams = {action: 'delete'}
      this.waresModal = true
    },
    onOpenModal(params){
      this.waresParams = params
      this.waresModal = true
    },
    addGoodsItem() {
      this.inProcess = true
      this.$store.dispatch("statistics/addNewGoodsItem").then(() => {
        const tabIndexButtons = document.querySelectorAll('.ware-item-toggle')
        tabIndexButtons[tabIndexButtons.length - 1].focus()
        this.inProcess = false
      }).catch(() => {
        this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
        this.inProcess = false
      })
    },
    sortGoods(sortBy) {
      this.$store.dispatch("statistics/sortGoods", { sortBy });
    },
  },
};
</script>
