<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col
        :class="{'blocked-box' : blocked_visibility}"
        cols="6"
      >
        <importer />
      </v-col>
      <v-col
        cols="6"
        :class="{'blocked-box' : blocked_visibility}"
      >
        <v-row class="statistics-box mx-0 ml-n1">
          <contragent :collapsed.sync="collapsed" />
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row
          class="statistics-box mx-0 mt-2 mb-0"
          :class="{'blocked-box' : blocked_visibility}"
        >
          <deal :collapsed.sync="collapsed" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Importer from "./contragents/importer.vue";
import Contragent from "./contragents/counter-agent.vue";
import Deal from "./contragents/deal.vue";
import blockVisibility from '@/mixins/block-visibility'
import { mapGetters } from 'vuex';

export default {
  components: {
    Importer,
    Contragent,
    Deal,
  },
  mixins: [blockVisibility],
  data: () => ({
    collapsed: false
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected"
    })
  }
};
</script>

<style scoped>
.block-heading {
  width: 100%;
  background-color: #d9d9d9;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}
.block-heading-right {
  margin: 0 8px 10px 0;
}
.block-heading-left {
  margin: 0 0 0 16px;
}
#individual {
  font-size: 8px !important;
  font-weight: bold !important;
}
.contragent-countries :first-child {
  flex: 1;
}
.contragent-countries :last-child {
  flex: 2;
}
.contagent-currency :first-child {
  flex: 1;
}
.contagent-currency :last-child {
  flex: 2;
}
</style>
