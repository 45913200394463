<template>
  <v-col cols="12">
    <!-- COLLAPSED-->

    <!-- <v-row
      v-show="collapsed"
      class="mx-n2"
    >
      <v-col cols="2">
        <span>{{ deal_details_json.deal_operation_code }}</span>
      </v-col>
      <v-col cols="2">
        <span>{{ deal_details_json.deal_feature_code }}</span>
      </v-col>
      <v-col cols="3">
        <span>{{ deal_details_json.delivery_type_code }}</span>
      </v-col>
      <v-col cols="1">
        <span>{{ deal_details_json.invoice_currency_letter }}</span>
      </v-col>
      <v-col cols="4">
        <span>{{ deal_details_json.invoice_cost }}</span>
      </v-col>
    </v-row> -->

    <!-- COLLAPSED END-->
    <v-row
      :id="block_id"
      v-click-outside="triggerOnFocus"
      class="mt-0 pb-0"
    >
      <!-- шапка -->

      <!-- шапка конец-->
      <v-col
        cols="1"
        class="pr-0"
      >
        <label>
          <span class="font-weight-bold">Код ТО
            <v-menu
              offset-x
            >
              <template #activator="{ on, attrs }">
                <v-icon
                  tabindex="-1"
                  class="mx-1"
                  small
                  dense
                  v-bind="attrs"
                  v-on="on"
                >mdi-tune</v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-if="!customFieldCheckerNull(customs, deal_details_json.customs_code, 'code')"
                  @click="deal_details_json.customs_code = ''"
                >
                  Произвольное значение
                </v-list-item>
                <v-list-item
                  v-else
                  @click="deal_details_json.customs_code = null"
                >
                  Значение из справочника
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
        </label>
        <div class="d-flex">
          <v-text-field
            v-show="customFieldCheckerNull(customs, deal_details_json.customs_code, 'code')"
            ref="customs_code__custom"
            v-model="deal_details_json.customs_code"
            v-mask="'##'"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
          <v-autocomplete
            v-show="!customFieldCheckerNull(customs, deal_details_json.customs_code, 'code')"
            ref="customs_code"
            v-model="deal_details_json.customs_code"
            auto-select-first
            :items="customs"
            item-text="text"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
          />
        </div>
      </v-col>

      <v-col
        cols="2"
        class="pl-14"
      >
        <label>6 Торгующая страна
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item @click="openCountries">
                Поиск в справочнике
              </v-list-item>
              <v-list-item
                v-if="!customFieldCheckerNull(countries, deal_details_json.trade_country_letter, 'letterCode')"
                @click="deal_details_json.trade_country_letter = ''"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="deal_details_json.trade_country_letter = null"
              >
                Значение из справочника
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <v-text-field
          v-show="customFieldCheckerNull(countries, deal_details_json.trade_country_letter, 'letterCode')"
          ref="trade_country_letter__custom"
          v-model="deal_details_json.trade_country_letter"
          v-mask="'XX'"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="unsetRegion('trade', 'deal_details_json')"
        />
        <v-autocomplete
          v-show="!customFieldCheckerNull(countries, deal_details_json.trade_country_letter, 'letterCode')"
          ref="trade_country_letter"
          v-model="deal_details_json.trade_country_letter"
          auto-select-first
          :items="countries"
          item-text="text"
          item-value="letterCode"
          :filter="filterBySearchField"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="unsetRegion('trade', 'deal_details_json')"
        />
      </v-col>
      <v-col
        cols="1"
        class="px-0"
      >
        <label>
          Регион
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                small
                dense
                v-bind="attrs"
                tabindex="-1"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item @click="openRegions">
                Поиск в справочнике
              </v-list-item>
              <v-list-item
                v-if="!customFieldCheckerNull(regionsForSelectedCountry, deal_details_json.trade_country_region, 'code')"
                @click="deal_details_json.trade_country_region = ''"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="deal_details_json.trade_country_region = null"
              >
                Значение из справочника
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <v-text-field
          v-show="customFieldCheckerNull(regionsForSelectedCountry, deal_details_json.trade_country_region, 'code')"
          ref="trade_country_region__custom"
          v-model="deal_details_json.trade_country_region"
          v-mask="'###'"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
        <v-autocomplete
          v-show="!customFieldCheckerNull(regionsForSelectedCountry, deal_details_json.trade_country_region, 'code')"
          ref="trade_country_region"
          v-model="deal_details_json.trade_country_region"
          auto-select-first
          :items="regionsForSelectedCountry"
          item-text="code"
          item-value="code"
          :filter="filterBySearchField"
          outlined
          dense
          background-color="white"
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="2"
        align-self="end"
      >
        <v-text-field
          :value="tradeCountryRegionName"
          disabled
          outlined
          dense
          background-color="#E1E1E1"
          hide-details="auto"
        />
      </v-col>
      <v-col
        cols="3"
        class="pl-16"
      >
        <label>
          7 Сделка
          <v-menu offset-x>
            <template #activator="{ on, attrs }">
              <v-icon
                class="mx-1"
                tabindex="-1"
                small
                dense
                v-bind="attrs"
                v-on="on"
              >mdi-tune</v-icon>
            </template>
            <v-list>
              <v-list-item @click="openDealTypes">
                Поиск в справочнике
              </v-list-item>
              <v-list-item
                v-if="!customFieldCheckerNull(characters, deal_details_json.deal_operation_code, 'code')"
                @click="clearDealType()"
              >
                Произвольное значение
              </v-list-item>
              <v-list-item
                v-else
                @click="clearDealTypeToDefault()"
              >
                Значение из справочника
              </v-list-item>
              <v-list-item
                @click="hideTooltips = !hideTooltips"
              >
                {{ hideTooltips ? 'Включить' : 'Отключить' }} подсказки
              </v-list-item>
            </v-list>
          </v-menu>
        </label>
        <div class="d-flex">
          <v-text-field
            v-show="customFieldCheckerNull(characters, deal_details_json.deal_operation_code, 'code')"
            ref="deal_operation_code__custom"
            v-model="deal_details_json.deal_operation_code"
            v-mask="'###'"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            class="mr-2"
          />
          <v-tooltip
            key="operation"
            bottom
            max-width="300px"
            class="d-none"
            :disabled="!deal_details_json.deal_operation_code || hideTooltips"
          >
            <template #activator="{ on, attrs }">
              <v-autocomplete
                v-show="!customFieldCheckerNull(characters, deal_details_json.deal_operation_code, 'code')"
                ref="deal_operation_code"
                v-model="deal_details_json.deal_operation_code"
                class="mr-2"
                v-bind="attrs"
                :items="characters"
                item-text="code"
                item-value="code"
                auto-select-first
                outlined
                dense
                background-color="white"
                hide-details="auto"
                v-on="on"
              />
            </template>
            <span>{{ operationName }}</span>
          </v-tooltip>
          <v-text-field
            v-show="customFieldCheckerNull(features, deal_details_json.deal_feature_code, 'code')"
            ref="deal_feature_code__custom"
            v-model="deal_details_json.deal_feature_code"
            v-mask="'##'"
            outlined
            class="mr-2"
            dense
            hide-details="auto"
            background-color="white"
          />
          <v-tooltip
            key="feature"
            bottom
            max-width="300px"
            class="d-none"
            :disabled="!deal_details_json.deal_feature_code || hideTooltips"
          >
            <template #activator="{ on, attrs }">
              <v-autocomplete
                v-show="!customFieldCheckerNull(features, deal_details_json.deal_feature_code, 'code')"
                ref="deal_feature_code"
                v-model="deal_details_json.deal_feature_code"
                v-bind="attrs"
                class="mr-2"
                :items="features"
                item-text="code"
                item-value="code"
                auto-select-first
                outlined
                dense
                background-color="white"
                hide-details="auto"
                v-on="on"
              />
            </template>
            <span>{{ featureName }}</span>
          </v-tooltip>
          <div>
            <v-text-field
              v-show="customFieldCheckerNull(delivery, deal_details_json.delivery_type_code, 'code')"
              ref="delivery_type_code__custom"
              v-model="deal_details_json.delivery_type_code"
              v-mask="'##'"
              outlined
              dense
              hide-details="auto"
              :disabled="procedure_name === 'ИМ'"
              :background-color="procedure_name === 'ИМ' ? '#E1E1E1' : 'white' "
            />
            <v-tooltip
              key="delivery"
              bottom
              max-width="300px"
              class="d-none"
              :disabled="!deal_details_json.delivery_type_code || hideTooltips"
            >
              <template #activator="{ on, attrs }">
                <v-autocomplete
                  v-show="!customFieldCheckerNull(delivery, deal_details_json.delivery_type_code, 'code')"
                  ref="delivery_type_code"
                  v-model="deal_details_json.delivery_type_code"
                  v-bind="attrs"
                  :items="delivery"
                  item-text="code"
                  item-value="code"
                  auto-select-first
                  outlined
                  dense
                  hide-details="auto"
                  :disabled="procedure_name === 'ИМ'"
                  :background-color="procedure_name === 'ИМ' ? '#E1E1E1' : 'white' "
                  v-on="on"
                />
              </template>
              <span>{{ diliveryName }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="selected"
        cols="3"
      >
        <div>
          <v-alert
            v-if="deal_details_json.delivery_type_code && procedure_name !== 'ИМ'"
            height="64"
            dense
            text
            color="grey"
            outlined
            class="mb-0 py-1"
          >
            <span style="font-size:11px;"> {{ diliveryName }}</span>
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import { getCountryDigitCodeByLetterCode , unsetRegion } from "@/helpers/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { showCatalog, catalogValueSelected, setContragent, customsProcedureChanged } from "@/events/statistics";
import customFieldChecker from '@/mixins/custom-field-checker'
import {deal_details as onDealDetails} from '@/events/statistics/control'
import {highlightField} from '@/helpers/control'

export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, customFieldChecker],
  data: () => ({
    block_id: "statistics-deal-block",
    isIndividual: false,
    items: [],
    deal_details: {
      declaration_id: null,
    },
    procedure_name: null,
    deal_details_json: {
      trade_country_letter: "",
      trade_country_region: "",
      customs_code: "",
      deal_operation_code: "",
      deal_feature_code: "",
      delivery_type_code: "",
      invoice_currency_letter: "",
      invoice_cost: "",
    },
    // поиск контрагентов по справочнику
    hideTooltips: true
  }),
  computed: {
    ...mapGetters({
      selected: "statistics/getSelected",
      wares: "statistics/getSelectedWares",
      currencies: "catalogs/getNsiCurrencies",
      customs: "catalogs/getNsiCustoms",
      countries: "catalogs/getNsiCountries",
      characters: "catalogs/getNsiDealCharactersSD",
      features: "catalogs/getNsiDealFeaturesSD",
      delivery: "catalogs/getNsiTypesOfDelivery",
      regions: "catalogs/getNsiRegions",

    }),
    regionsForSelectedCountry(){
      if(this.deal_details_json.trade_country_letter){
        const code = getCountryDigitCodeByLetterCode(this.countries,this.deal_details_json.trade_country_letter)
        return this.regions.filter(i => i.countryDigit === code || i.countryDigit === null)
      }
      return this.regions
    },
    tradeCountryRegionName() {
      const region =
        this.regions.find(
          (i) => i.code === this.deal_details_json.trade_country_region
        ) || {};
      return region?.name ?? "";
    },
    operationName(){
      const {name} = this.characters.find(i => i.code === this.deal_details_json.deal_operation_code) || {}
      return name || "";
    },
    featureName(){
      const {name} = this.features.find(i => i.code === this.deal_details_json.deal_feature_code) || {}
      return name || "";
    },
    diliveryName(){
      const {name} = this.delivery.find(i => i.code === this.deal_details_json.delivery_type_code) || {}
      return name || "";
    }
  },
  watch: {
    selected:{
      handler(){
        this.setFields();
        this.procedure_name = this.selected.type_declaration.customs_procedure
        if(this.selected.type_declaration.customs_procedure === 'ИМ'){
          this.deal_details_json.delivery_type_code = null
        }
      },
      deep:true
    },
    search(nv) {
      if (nv && Object.keys(nv).length) {
        // this.updateFieldsOnSearch(nv);
        setContragent.trigger(nv)
        this.search = null;
        this.contragents = [];
        // this.fieldsBeenVisitedByUser();
        // this.triggerOnChange();
      }
    },
  },
  created(){
    if(this.selected && this.selected.type_declaration){
      this.procedure_name = this.selected.type_declaration.customs_procedure
    }
    onDealDetails.subscribe(this.highlightField)
    catalogValueSelected.subscribe(this.updatesResolver, [
      'deal_details_json.trade_country_letter',
      'deal_details_json.trade_country_region',
      'deal_details_json.dealtypes',
      'deal_details_json.invoice_currency_letter'
    ])
    customsProcedureChanged.subscribe(this.onProcedureChange)
  },
  beforeDestroy(){
    onDealDetails.unsubscribe()
    catalogValueSelected.unsubscribe()
    customsProcedureChanged.unsubscribe()
  },
  methods: {
    unsetRegion,
    highlightField,
    onProcedureChange({value}){
      // Когда проставляется новое значение типа документа
      // и если есть тип доставки то его надо очистить
      if(value === 'ИМ' && this.deal_details_json.delivery_type_code !== null){
        this.procedure_name = 'ИМ'
        this.deal_details_json.delivery_type_code = null
        //Обновляем напрямую тк возможно некорректное поведение когда выбран новый тип документа и далее клик идет на поле в данном блоке
        // Вызывается обновление блока Основной информации (ОИ)
        // В данном блоке hasChanges = true
        // Но после получения ответа от ОИ =>watch =>  setFields => hasChanges = false
        // Как результат уже после выхода из данного блока не происходит обновление
        this.uploadData().then(() => {
          this.$snackbar({text:'Обновлено [Сделка]', color:'green', top:false})
        }).catch(() => {
          this.$snackbar({text:'Ошибка [Cделка]', color:'red', top:false})
        })
      }
    },
    clearDealType(){
      this.deal_details_json.deal_operation_code = ''
      this.deal_details_json.deal_feature_code = ''
      this.deal_details_json.delivery_type_code = ''
    },
    clearDealTypeToDefault(){
      this.deal_details_json.deal_operation_code = null
      this.deal_details_json.deal_feature_code = null
      this.deal_details_json.delivery_type_code = null
    },
    updatesResolver({code, field, catalog = null}){
      if(code && field){
        if(field === 'deal_details_json.dealtypes'){
          this.deal_details_json[catalog] = code
        }else if(field === 'deal_details_json.trade_country_letter'){
          if(code !== this.deal_details_json.trade_country_letter){
            this.unsetRegion('trade', 'deal_details_json')
          }
          this.deal_details_json.trade_country_letter = code
        }
        else{
          const [parent, child] = field.split('.')
          this[parent][child] = code
        }
        this.fieldsBeenVisitedByUser()
        this.triggerOnChange()
      }
    },
    setFields() {
      const { deal_details: { declaration_id, deal_details_json } = {} } =
        this.selected;

      for (let key in this.deal_details_json) {
        if (key in deal_details_json) {
          this.deal_details_json[key] = deal_details_json[key];
        }
      }
      this.deal_details.declaration_id = declaration_id;
      this.setHasChangesFalse();
    },
    uploadData() {
      const payload = {
        ...this.deal_details,
        deal_details_json: {
          ...this.deal_details_json
        },
      };
      const deal_details = this.convertEmptyStringsToNull(payload);
      return this.$store.dispatch("statistics/uploadBlockData", {
        name: "deal_details",
        value: deal_details,
      });
    },
    openDealTypes() {
      showCatalog.trigger({
        type: "dealtypes",
        search:"",
        field: "deal_details_json.dealtypes",
      });
    },
    openCurrencies() {
      showCatalog.trigger({
        type: "currencies",
        search: this.deal_details_json.invoice_currency_letter,
        field:"deal_details_json.invoice_currency_letter" });
    },
    openCountries() {
      showCatalog.trigger({
        type: "countries",
        search: this.deal_details_json.trade_country_letter,
        field:"deal_details_json.trade_country_letter" });
    },
    openRegions() {
      showCatalog.trigger({
        type: "regions",
        search: this.deal_details_json.trade_country_region,
        field:"deal_details_json.trade_country_region",
        filter:{
          countryDigit:getCountryDigitCodeByLetterCode(this.countries,this.deal_details_json.trade_country_letter)
        }});
    },
    recalculate(){
      //Ожидаем пока обновится товар, если он редактировался
      setTimeout(() => {
        this.deal_details_json.invoice_cost = this.wares.reduce((acc, obj) => {
          return acc + obj.invoice_cost
        }, 0)
        this.fieldsBeenVisitedByUser()
        this.triggerOnChange()
      }, 250)
    },
  },
};
</script>
 <style scoped>
span {
  min-height: 24px;
  display: block;
}
</style>
